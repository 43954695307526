var pdAsyncScansLength = 3000;
// var asyncScansLength;
var pdAsyncScans = 2;
var pdAsyncScansLength = 3000;
var asyncScans;
var scanArray = [];
// var sessionId = 0;

export function startApp(iDeviceId) {
  // setEvent([["event_type", "start_session"]]);

  // check if front camera or rear camera
  // iDeviceId = 0 => front camera

  if (typeof initWebcamSource === "function") {
    (async () => {
      await initWebcamSource(iDeviceId, "video");
      // start();
    })();
    // document.getElementsByClassName('loader-box')[0].style.display = 'initial';
    // document.getElementsByClassName('camera-selection')[0].style.display = 'none';
  }
}

// export function startSessionOuterScope() {
//   // console.log("about to start")
//   start();
// }

export function startSessionOuterScope() {
  // console.log("about to start")
  // start();
  startSession()
}


export function stopApp() {
  setEvent([['event_type', 'end_session']]);
  window.clearInterval(keepAliveTimeout);
  pdResult = undefined;
  shResult = undefined;
  shEyeImage = undefined;
  pdEyeImage = undefined;
  shScanCount = 0;
  pdScanCount = 0;
  sessionId = 0;
  scanTries = 0;
  state = "intro";
  previousState = undefined;
  isManualPd = undefined;

  hideMesh();
  stop();
  window.dispatchEvent(new CustomEvent("endSession", { detail: true }));

  // window.location.replace("https://mmwmdaniel.z13.web.core.windows.net/");
  // window.location.replace("https://192.168.0.183:3000");

}

export function reScanOuterScope(scanType) {
  console.log("rescan")
  reScan(scanType)
}

export function testingfunc() {
  doTrackDump(800, 9, false);
}
export function generateClientId() {

  const clientID = uuidv4();
  console.log("generateClientId ", clientID)
  return clientID;

}
export function generateSessionId() {

  sessionId = uuidv4();
  console.log("generateSessionId ", sessionId)
  return sessionId;

}
export function startEvent(iAttributes) {
  // console.log("call setEvent ", iAttributes)
  setEvent(iAttributes)
}
export function startPDScanOuterScope() {
  startPdScan();
}



import React, { Fragment } from "react";
import styles from "./ResultCard.module.css";
import {
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
} from "@walmart-web/livingdesign-components";
import { ArrowRight } from "@livingdesign/icons";
import { ArrowLeft } from "@livingdesign/icons";
import * as Icon from "@livingdesign/icons";

const ResultCard = ({
  sumResult,
  title,
  rightLabel,
  rightValue,
  leftLabel,
  leftValue,
}) => {
  return (
    <div>
      <div className={styles.cardTitle}>
        <span>{title}</span>
        <span>
          <b>{sumResult}</b>
        </span>
      </div>
      <Card size="small" className={styles.cardWrapper}>
        <CardContent className={styles.cardResults}>
          <div>
            <span>{rightLabel}</span>
          </div>
          <div className={styles.rowRightSide}>
            <ArrowRight size="medium" />
            <span>
              <b>{rightValue}</b>
            </span>
          </div>
        </CardContent>

        <Divider />

        <CardContent className={styles.cardResults}>
          <div>
            <span>{leftLabel}</span>
          </div>
          <div className={styles.rowRightSide}>
            <ArrowLeft size="medium" />
            <span>
              <b>{leftValue}</b>
            </span>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResultCard;

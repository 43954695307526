import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Content.module.css";
import visionCenterSVG from "../assets/visionCenter_associate_handoff_circle.svg";
import { Button } from "@walmart-web/livingdesign-components";
import { Link } from "react-router-dom";
import { Spinner } from "@walmart-web/livingdesign-components";
import screenfull from "screenfull";
import { StorePicker } from './StorePicker'
import storelocation from "../assets/store-location.svg"
import { useDeviceOrientation } from "../hooks/useOrientation";
import { DataContext } from "../App";
import { generateSessionId, startEvent } from "../../public/API/pd_scan";
import { LanguageContext } from '../LanguageContext';
//import LanguageContext from './LanguageSelector';
import { Language } from "@mui/icons-material";
//import { currentLanguage } from "../i18n";

const Content = () => {
  const [shouldShowStorePicker, setShouldShowStorePicker] = useState(false);
  const navigate = useNavigate();
  const selectedStoreID = JSON.parse(localStorage.getItem("selectedStoreID"));


  const cameraStylingHAndler = () => {
    if (screenfull.isEnabled) {
      screenfull.request();
    }
    document.querySelector("#divCanvas").style.cssText = `display: flex;`;
    document.querySelector("#webGLCanvas").style.cssText = `
       z-index: 1; 
       position: absolute;
       width: 100%;
       top: -200px;
       transform: scaleX(-1)
   `;

    const sessionId = generateSessionId();
    console.log("content sessionId ", sessionId)
    //localStorage.setItem('clientID', newClientID);

    startEvent([["event_type", "start_session"]]);

    navigate("/prescan");
    //     if (screenfull.isEnabled) {
    // 	screenfull.on('change', () => {
    // 		console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
    // 	});
    // }

  };
  // const settingsData = useContext(DataContext);
  //const { language, settingsData } = useContext(LanguageContext)
  //const { lang } = useContext(LanguageContext);
  const currentLanguage = useContext(LanguageContext).appLanguage;
  console.log("currentLanguage ", currentLanguage)

  // const currentLanguage = useMemo(
  //   () => localStorage.getItem('appLanguage'),
  //   []
  // );
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  // const { title, sub_title } = settingsData.languageText;
  // console.log("check ", title[language], sub_title[language])

  //localStorage.removeItem("selectedStoreID");
  // localStorage.setItem('visitCount', 1);
  // navigate("/");
  // window.location.reload()
  // }

  // const visitCount = useMemo(() =>
  //   (Number(localStorage.getItem('visitCount')) + 1),
  //   []
  // );
  // console.log("Landing visitCount ", visitCount);
  // localStorage.setItem('visitCount', visitCount);


  return (
    <div className={styles.content}>

      <div className={styles.titleWrapper}>
        {/* <p className={styles.title}>VisualEYEz</p> */}
        <p className={styles.title}>{settingsData.languageText.title[currentLanguage]}</p>
        {/* <p className={styles.subTitle}>Digital measurements tool</p> */}
        <p className={styles.subTitle}>{settingsData.languageText.sub_title[currentLanguage]}</p>
      </div>
      <img src={visionCenterSVG} alt="visionCenterSVG" />
      <Button
        onClick={cameraStylingHAndler}
        className={styles.ctaBtn}
        variant="primary"
      >

        {settingsData.languageText.start_session[currentLanguage]}
      </Button>
      <p className={styles.wtchBtn}>{settingsData.languageText.instruction[currentLanguage]}</p>

      <p className={styles.footer} onClick={() => setShouldShowStorePicker(true)}>
        <img src={storelocation} alt="store" />
        <div></div>
        <span className={styles.storeName}>{selectedStoreID}</span>
      </p>
      {shouldShowStorePicker && <StorePicker onClose={() => setShouldShowStorePicker(false)}></StorePicker>}
    </div>
  );
};

export default Content;

import React, { useMemo, useContext } from "react";
import styles from "./Distance.module.css";
import step2CenterCircleFail from '../../assets/step2CenterCircleFail.svg'
import step2CenterCircleApprove from '../../assets/step2CenterCircleApprove.svg'
import step2BackgroundCircleApprove from '../../assets/step2BackgroundCircleApprove.svg'
import step2BackgroundCircleFail from '../../assets/step2BackgroundCircleFail.svg'
//import { currentLanguage } from "../../i18n";
import { LanguageContext } from "../../LanguageContext";


const Distance = ({ vectorsData, thresholdMin, thresholdMax }) => {
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText

  const step2CenterDiv =
    vectorsData[4] < thresholdMin ? (
      <>
        <h1 style={{ zIndex: 6, position: "absolute", color: "black" }}>
          {/* Too close */}
          {t.iris_close[currentLanguage]}
        </h1>
        <img
          className={styles.step2CenterCircle}
          alt="step2CenterCircleFail"
          src={step2CenterCircleFail}
          style={{ height: 400 }}
        />
      </>
    ) : vectorsData[4] > thresholdMax ? (
      <>
        <h1 style={{ zIndex: 6, position: "absolute", color: "black" }}>
          {/* Too far */}
          {t.iris_far[currentLanguage]}
        </h1>
        <img
          className={styles.step2CenterCircle}
          alt="step2CenterCircleFail"
          src={step2CenterCircleFail}
          style={{ height: 400 }}
        />
      </>
    ) : (
      <img
        className={styles.step2CenterCircle}
        alt="step2CenterCircleApprove"
        src={step2CenterCircleApprove}
        style={{ height: 400, opacity: 2 }}
      />
    );

  const step2BackgroungDiv =
    vectorsData[4] < thresholdMin ? (
      <img
        className={styles.step2CenterCircleBackground}
        alt="step2CenterCircleFailBackground"
        src={step2BackgroundCircleFail}
        style={{ height: vectorsData[4] * 10, opacity: 0.5 }}
      />
    ) : vectorsData[4] > thresholdMax ? (
      <img
        className={styles.step2CenterCircleBackground}
        alt="step2CenterCircleFailBackground"
        src={step2BackgroundCircleFail}
        style={{ height: vectorsData[4] * 10, opacity: 0.5 }}
      />
    ) : (
      <img
        className={styles.step2CenterCircleBackground}
        alt="step2CenterCircleApprove"
        src={step2BackgroundCircleApprove}
        style={{ height: vectorsData[4] * 10, opacity: 2 }}
      />
    );

  const noFace =
    <>
      <h1 style={{ zIndex: 6, position: "absolute", color: "black" }}>
        {/* No face */}
        {t.iris_no_face[currentLanguage]}
      </h1>
      <img
        className={styles.step2CenterCircle}
        alt="step2CenterCircleFail"
        src={step2CenterCircleFail}
        style={{ height: 400 }}
      />
    </>



  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {(!vectorsData[4]) ? noFace :
        <>
          {step2CenterDiv}
          {step2BackgroungDiv}
        </>
      }
    </div>
  );
};

export default Distance;

import React from "react";
import styles from "./FaceDirection.module.css";
// import step4AllApprove from "../../assets/step4AllApprove.svg";
import step4AllApprove from "../../assets/step4AllApprove.svg";
import step4XaxisImage from "../../assets/step4Xaxis.svg";
import step4YaxisImage from "../../assets/step4Yaxis.svg";
import XYbackground from "../../assets/X+Ybackground.svg";
import step4backgroundYaxis from "../../assets/step4backgroundYaxis.svg";
import YaxisApproveSH from "../../assets/YaxisApproveSH.svg";

const FaceDirection = ({ vectorsData, thresholdXaxisMin, thresholdXaxisMax, thresholdYaxisMin, thresholdYaxisMax, isShScan }) => {
  const faceExist = vectorsData[0] !== 0 && vectorsData[1] !== 0
  const xAxisRange = faceExist ? (thresholdXaxisMin < vectorsData[1] && vectorsData[1] < thresholdXaxisMax) : false;
  const yAxisRange = faceExist ? thresholdYaxisMin < vectorsData[0] && vectorsData[0] < thresholdYaxisMax : false;
  // console.log(xAxisRange)
  const isInRange = (isShScan) ? xAxisRange : (xAxisRange && yAxisRange);
  return (
    <div className={styles.centerWrapper}>

      {(isInRange && !isShScan) ?
        (<img
          alt="step4AllApprove"
          src={step4AllApprove}
          className={styles.step4AllApprove} />)
        : (!isInRange && isShScan) ?
          <img
            alt="step4backgroundYaxis"
            src={step4backgroundYaxis}
            className={styles.yAxisSH}
          /> : ((!isInRange)) ?
            <img
              alt="XYbackground"
              src={XYbackground}
              className={styles.xyBackground}
            /> : 'no face'}

      {(isShScan && xAxisRange) && <img alt="YaxisApproveSH" src={YaxisApproveSH} className={styles.yAxisSHApprove} />}
      {(!isShScan && xAxisRange && !yAxisRange) && <img alt="step4YaxisImage" src={step4YaxisImage} className={styles.yAxis} />}
      {(!isShScan && yAxisRange && !xAxisRange) && <img alt="step4xaxisImage" src={step4XaxisImage} className={styles.xAxis} />}
    </div>
  );
};

export default FaceDirection;



{/* {isInRange ? (
        <img
          alt="step4AllApprove"
          src={step4AllApprove}
          className={styles.xAxis}
        />
      ) : (
        <>
          <img
            alt="XYbackground"
            src={XYbackground}
            className={styles.xyBackground}
          />

          <img
            alt="step4YaxisImage"
            src={step4YaxisImage}
            className={styles.yAxis}
            style={{ left: `${vectorsData[1]}px` }}
          />
          <img
            alt="step4XaxisImage"
            src={step4XaxisImage}
            className={styles.xAxis}
            style={{ top: `${vectorsData[0]}px` }}
          />
        </>
      )} */}
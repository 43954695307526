import React, { Fragment } from "react";
import styles from "./PdManualCard.module.css";
import {
  ButtonGroup,
  Card,
  TextField,
} from "@walmart-web/livingdesign-components";
import { useState, useRef, useContext, useMemo, useEffect } from "react";
import { LanguageContext } from "../../LanguageContext";

const PdManualCard = ({
  rightPD,
  setRightPD,
  leftPD,
  setLeftPD,
  //leftFieldError,
  //setLeftFieldError,
}) => {
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;
  const currentLanguage = useContext(LanguageContext).appLanguage;

  const leftPDRef = useRef();
  const rightPDRef = useRef();

  const [rightFieldError, setRightFieldError] = useState();
  const [leftFieldError, setLeftFieldError] = useState();

  const [focusedField, setFocusedField] = useState();

  useEffect(() => {
    leftPDRef.current.focus();
  }, []);

  useEffect(() => {
    if (rightPD !== undefined) {
      const { isValid, error } = validateValue(rightPD);
      setRightFieldError(isValid === true ? undefined : error);
    }
  }, [rightPD]);

  useEffect(() => {
    if (leftPD !== undefined) {
      const { isValid, error } = validateValue(leftPD);
      setLeftFieldError(isValid === true ? undefined : error);
    }
  }, [leftPD]);

  const stringValueToNumber = (value) => {
    if (value.length <= 2) {
      return parseFloat(value);
    } else if (value.length === 3) {
      return parseFloat(`${value[0]}${value[1]}.${value[2]}`);
    } else {
      console.log(value);
      throw new Error("value is not valid - only 3 digits are allowed");
    }
  };

  const validateValue = (value) => {
    const valueNumber = stringValueToNumber(value);
    if (valueNumber < 20) {
      // return { isValue: false, error: "Too low" };
      return { isValue: false, error: t.pd_value_low[currentLanguage] };
    } else if (valueNumber > 45) {
      return { isValue: false, error: t.pd_value_high[currentLanguage] };
      // return { isValue: false, error: "Too high" };
    }

    return { isValid: true };
  };

  const handleInputKeyDown = (field, event) => {
    const keyPressed = event.key;

    const fieldSetter = field === "right" ? setRightPD : setLeftPD;

    if (keyPressed === "Backspace") {
      fieldSetter((value) => value.slice(0, -1));
      return;
    }

    if (!/[0-9]/.test(keyPressed)) {
      return;
    }

    fieldSetter((value) => {
      if (value === undefined) {
        return keyPressed;
      }

      const newValue = value.length < 3 ? value + keyPressed : value;

      if (newValue.length === 3) {
        rightPDRef.current.focus();
      }
      console.log("fieldSetter( ", value, "new ", newValue);
      return newValue;
    });
  };

  const formatFieldValue = (value) => {
    let formattedValue = "";
    if (value === undefined || value.length === 0) {
      return undefined;
    } else if (value.length === 1) {
      formattedValue = value;
    } else if (value.length === 2) {
      formattedValue = value + ".";
    } else if (value.length === 3) {
      formattedValue = `${value[0]}${value[1]}.${value[2]}`;
    }
    return formattedValue + " mm";
  };

  return (
    <Card size="large" className={styles.cardWrapper}>
      <span className={styles.cardTitle}>
        {t.manual_pd_title[currentLanguage]}
        {/* Enter your PD measurements */}
      </span>

      <div className={styles.form}>
        <TextField
          // type="number"
          ref={leftPDRef}
          size="large"
          className={styles.testInput}
          // label="Left PD"
          label={t.left_pd_title[currentLanguage]}
          placeholder="00.0mm"
          //helperText="possible values from 20 to 45"
          helperText={t.pd_legal_values[currentLanguage]}
          // onChange={(event) => onInputChange("left", event.target.value)}
          onKeyDown={(event) => handleInputKeyDown("left", event)}
          value={formatFieldValue(leftPD)}
          error={leftFieldError}
        />

        <TextField
          // type="number"
          ref={rightPDRef}
          size="large"
          className={styles.testInput}
          //label="Right PD"
          label={t.right_pd_title[currentLanguage]}
          // helperText="possible values from 20 to 45"
          helperText={t.pd_legal_values[currentLanguage]}
          placeholder="00.0mm"
          //onChange={(event) => handleInputKeyDown("right", event.target.value)}
          onKeyDown={(event) => handleInputKeyDown("right", event)}
          value={formatFieldValue(rightPD)}
          error={rightFieldError}
        // onFocus={() => setFocusedField("right")}
        // onBlur={() => setFocusedField(undefined)}
        />
      </div>
    </Card>
  );
};

export default PdManualCard;

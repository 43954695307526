import React from "react";

const StepsContent = (props) => {
  return (
    <>
      <img src={props.imgSrc} width={390} alt={props.imgText} />
      <span>{props.spanText}</span>
      {props.ulContent}
    </>
  );
};

export default StepsContent;

import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import styles from "./LandingComp.module.css";
import { Button } from "@walmart-web/livingdesign-components";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, TextField } from "@walmart-web/livingdesign-components";
import screenfull from "screenfull";
import storefront from "../assets/storefront_circle.svg";
import storelocation from "../assets/store-location.svg"
import { useDebounce } from "../hooks/UseDebounce";
import data from "./storelist.json";
import data1 from "./storemock.json";
import { generateClientId } from "../../public/API/pd_scan";
//import { currentLanguage } from "../i18n";
import { LanguageContext } from '../LanguageContext';

// export const MockStores = [{
//   id: '1234',
//   name: 'San Francisco'
// },
// {
//   id: '4321',
//   name: 'New York'
// }];
const LandingComp = () => {
  const [settingsData, setSettinsgData] = useState({});
  const currentLanguage = useContext(LanguageContext).appLanguage;

  useEffect(() => {
    const dataFetch = async () => {
      const data = await JSON.parse(localStorage.getItem("settingsData"))
      setSettinsgData(data)
    }
    dataFetch()
  }, [])

  // const settingsData = useMemo(
  //   () => JSON.parse(localStorage.getItem("settingsData")),
  //   []
  // );
  const t = settingsData?.languageText;
  const MockStores = data;

  // const [searchFieldValue, setSearchFieldValue] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const selectedStoreID = localStorage.getItem('selectedStoreID');
  const newValue = selectedStoreID ? selectedStoreID : "";
  const [searchFieldValue, setSearchFieldValue] = useState(newValue);
  const debouncedSearchFieldValue = useDebounce(searchFieldValue, 500);
  const navigate = useNavigate();
  const visitCount = useMemo(() =>
    (Number(localStorage.getItem('visitCount')) + 1),
    []
  );
  // const visitCount = useMemo(() =>
  //   (Number(localStorage.setItem('visitCount', 0))),
  //   []
  // );

  console.log("Landing visitCount ", visitCount);
  localStorage.setItem('visitCount', visitCount);

  useEffect(() => {
    setSelectedStore(
      MockStores.find((store) => store.Id === Number(debouncedSearchFieldValue))
    );
  }, [debouncedSearchFieldValue])


  const handleStoreNumberChange = (event) => {
    localStorage.setItem('visitCount', 1);
    const newClientID = generateClientId();
    localStorage.setItem('clientID', newClientID);
    if (event.target.value.startsWith('VC')) {
      setSearchFieldValue(event.target.value.replace('VC', ''));
    }
    else {
      setSearchFieldValue('');
    }
  }

  const handleConfirmClicked = () => {
    localStorage.setItem('selectedStoreID', selectedStore.Id);
    window.location.reload()
    //navigate("/landing");
  }



  return (
    <div className={styles.content}>
      <img src={storefront} style={{ height: 201 }} alt="store" />
      <span className={styles.cardTitle}>
        {/* Enter VC 4-Digit Store Number */}
        {t?.select_store_title[currentLanguage]}
      </span>
      <div className={styles.form}>
        <TextField
          // type="number"
          size="large"
          onChange={handleStoreNumberChange}
          // placeholder="VC"
          placeholder={t?.vc_tag[currentLanguage]}
          // value={`VC${searchFieldValue}`}
          value={`${t?.vc_tag[currentLanguage]}${searchFieldValue}`}

        />
      </div>
      {/* {selectedStore && selectedStore.name} */}
      {selectedStore &&
        <div className={styles.storeContent}>
          <img src={storelocation} alt="store" />
          <div></div>
          <span className={styles.storeName}> {selectedStore.Name}</span>
        </div>}

      <div className={styles.buttonContainer}>
        <Link to={`/`} style={{ textDecoration: "none" }}>
          <Button
            className={styles.btnWrapper}
            variant="primary"
            disabled={selectedStore === null}
            onClick={handleConfirmClicked}
          >
            {/* Confirm */}
            {t?.submit_store[currentLanguage]}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default LandingComp;

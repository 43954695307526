import React, { useEffect } from 'react'
import Content from './Content';
import LandingComp from './LandingComp';

export const ContentOrStoreSelector = () => {

    const selectedStoreID = localStorage.getItem('selectedStoreID');
    const visitCount = localStorage.getItem('visitCount');

    //return selectedStoreID ? <Content /> : <LandingComp />
    return visitCount > 1 ? <Content /> : <LandingComp />


}

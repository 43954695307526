import LandingComp from "../components/LandingComp";
import Header from "../components/Header";
import { ContentOrStoreSelector } from "../components/ContentOrStoreSelector";

export default function Landing() {
  return (
    <>
      <Header />
      <ContentOrStoreSelector />
    </>
  );
}

import { useEffect, useMemo } from "react";
import styles from "./PreScan.module.css";
// import Webcam from "react-webcam";
import { Pencil } from "@livingdesign/icons";
import OnboardingStepper from "../components/OnboardingStepper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PreScan() {
  let navigate = useNavigate();
  const visitCount = useMemo(() =>
    (Number(localStorage.getItem('visitCount')) + 1),
    []
  );
  localStorage.setItem('visitCount', visitCount);
  const hideCamera = () => {
    document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;
  };

  return (
    <div className={styles.main}>
      <div className={styles.overlaywrapper}>
        <OnboardingStepper />
      </div>
    </div>
  );
}

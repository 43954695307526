import React from "react";
import step3frameFail from "../../assets/step3frameFail.svg";
import step3frameApprove from "../../assets/step3frameApprove.svg";

const CenterFace = ({ vectorsData, thresholdXaxisMin, thresholdXaxisMax, thresholdYaxisMin, thresholdYaxisMax }) => {
  const faceExist = vectorsData[2] !== 0 && vectorsData[3] !== 0
  let step3YaxisLogic = faceExist ? vectorsData[3] > thresholdYaxisMin && vectorsData[3] < thresholdYaxisMax : false;
  let step3XaxisLogic = faceExist ? vectorsData[2] > thresholdXaxisMin && vectorsData[2] < thresholdXaxisMax : false;

  return step3YaxisLogic && step3XaxisLogic ? (
    <img
      alt="step3frameApprove"
      src={step3frameApprove}
      style={{ height: "40vh", position: "absolute", top: "29%", height: "280px" }}
    />
  ) : (
    <img
      alt="step3frameFail"
      src={step3frameFail}
      style={{ height: "40vh", position: "absolute", top: "29%", height: "280px" }}
    />
  );
};

export default CenterFace;

import React, { useState, useMemo, useContext } from "react";
import styles from "./OnboardingStepper.module.css";
import womanSVG from "../assets/womanPortrait_glasses__mask.svg";
import featuresSVG from "../assets/featuresSVGG.svg";
import noEntrySVG from "../assets/Status=Off.svg";
import { Button } from "@walmart-web/livingdesign-components";
import { Pencil } from "@livingdesign/icons";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import StepsContent from "./StepsContent";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { startSessionOuterScope, startApp } from '../../public/API/pd_scan';
import LoadingScreenUI from './scanloading/LoadingScreenUI';
import { Spinner } from "@walmart-web/livingdesign-components";
import { useDeviceOrientation } from "../hooks/useOrientation";
// import { currentLanguage } from "../i18n";
import { startEvent } from '../../public/API/pd_scan'
import { LanguageContext } from '../LanguageContext';



const OnboardingStepper = () => {
  // const currentLanguage = useMemo(
  //   () => localStorage.getItem('appLanguage'),
  //   []
  // );
  const currentLanguage = useContext(LanguageContext).appLanguage;
  console.log("currentLanguage onboarding ", currentLanguage, useContext(LanguageContext))

  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );

  const t = settingsData.languageText

  const { orientation, requestAccess } = useDeviceOrientation({ beta: 0 });

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let { scantype } = useParams();

  const handleNext = async () => {
    startEvent([['event_type', 'start_pd_intro']]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const startHandler = async () => {
    startEvent([['event_type', 'next_pd_text']]);
    setIsLoading(true)
    await requestAccess();

    startSessionOuterScope()
  };

  window.addEventListener("isCameraReady", (event) => {
    if (event.detail) {
      setIsLoading(false)
      //navigate(`/configuration/${scantype}`)
      navigate("/configuration/pd")
      console.log("Camera ready")
    } else { console.log("camera not ready yet!") }

  })

  //const navigateHanldler = useRescan("pd")

  const handlePdManual = () => {

    navigate("/pdmanual-page");
  };

  const nextButton = (
    <Button size="small" onClick={handleNext} className={styles.ctaBtn}>
      {t.next[currentLanguage]}
      <KeyboardArrowRight />
    </Button>
  );

  const startButton = (
    <Button
      size="small"
      variant="primary"
      onClick={startHandler}
      className={styles.ctaBtn}
    >
      {t.start[currentLanguage]}
    </Button>
  );

  const readyToStart = activeStep === 1;

  const ctaButton = readyToStart ? startButton : nextButton;
  const ulContent = (
    <ul>
      <li>
        <img src={noEntrySVG} width={30} alt="status off SVG" />
        {t.remove_glasses[currentLanguage]}
      </li>
      <li>
        <img src={noEntrySVG} width={30} alt="status off SVG" />
        {t.remove_mask[currentLanguage]}
      </li>
    </ul>
  );
  const ulText1 = (
    <ul>
      <p className={styles.intro}>

        {t.pd_intro[currentLanguage]}
      </p>
    </ul>
  );

  const ulText2 = (
    <ul>
      <p className={styles.intro}>
        {t.follow_instruction[currentLanguage]}
      </p>
    </ul>
  );

  return (
    isLoading ?
      <>
        <div className={styles.loadingWrapper}>
          <Spinner color="white" size="large" />
          {t.camera_loading_lbl[currentLanguage]}
        </div>
      </>
      :
      (<div className={styles.stepperWrapper}>
        <StepsContent
          imgSrc={readyToStart ? featuresSVG : womanSVG}
          imgAlt={readyToStart ? "features SVG" : "woman with mask SVG"}
          spanText={
            readyToStart
              ? ulText2
              : ulText1
          }
          ulContent={
            readyToStart ? "" : ulContent
          }
        />
        <div className={styles.stepsAndCtaBtn}>
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            //sx={{ backgroundColor: "transparent" }}
            sx={{
              backgroundColor: "transparent",
              "& .MuiMobileStepper-dot": {
                backgroundColor: '#B2B2B2',
                width: 6,
                height: 6
              },
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: 'white',
                width: 8,
                height: 8
              },
            }}
          />
          {ctaButton}
        </div>

      </div>)
    // <div>
    //     <Pencil size="small" onClick={handlePdManual} />
    //     <strong>{t.manual_pd_button[currentLanguage]}</strong>
    //   </div>
  );
};

export default OnboardingStepper;

import React, { useMemo, useContext } from "react";
import styles from "./OnboardingStepper.module.css";
import womanSVG from "../assets/womanPortrait_glasses__mask.svg";
import featuresSVG from "../assets/featuresSVGG.svg";
import noEntrySVG from "../assets/Status=Off.svg";
import greenCheck from "../assets/greenCheck.svg";
import { Button } from "@walmart-web/livingdesign-components";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import StepsContent from "./StepsContent";
import { useNavigate } from 'react-router-dom';
// import { currentLanguage } from "../i18n";
import { startEvent } from '../../public/API/pd_scan'
import { LanguageContext } from '../LanguageContext';
import { startSessionOuterScope, startApp } from '../../public/API/pd_scan';

const OnboardingStepper = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const currentLanguage = useContext(LanguageContext).appLanguage;
    const settingsData = useMemo(
        () => JSON.parse(localStorage.getItem("settingsData")),
        []
    );
    const t = settingsData.languageText;

    const startHandler = async () => {
        // if (DeviceMotionEvent.requestPermission === "function") {
        //     await DeviceOrientationEvent.requestPermission();
        // }
        startEvent([['event_type', 'next_sh_text']]);
        startSessionOuterScope()
        navigate('/configuration/sh');

    }

    // const nextButton = (
    //     <Button size="small" onClick={handleNext} className={styles.ctaBtn}>
    //         {/* Next */}
    //         {t.next[currentLanguage]}
    //         <KeyboardArrowRight />
    //     </Button>
    // );

    const startButton = (
        <Button
            size="small"
            variant="primary"
            onClick={startHandler}
            className={styles.ctaBtn}
        >
            {/* Start */}
            {t.start[currentLanguage]}
        </Button>
    );

    const readyToStart = activeStep === 1;

    const ctaButton = startButton;
    const ulContent = (
        <ul>
            <li>
                <img src={greenCheck} width={30} alt="status on SVG" />
                {t.put_glasses[currentLanguage]}
            </li>
            <li>
                <img src={greenCheck} width={30} alt="status on SVG" />
                {t.wear_glasses[currentLanguage]}
            </li>
            <li>
                <img src={noEntrySVG} width={30} alt="status off SVG" />
                {/* Remove mask */}
                {t.remove_mask[currentLanguage]}
            </li>
        </ul>
    );
    const ulTitle = (

        <p className={styles.intro}>
            {t.sh_intro[currentLanguage]}
        </p>

    );

    return (
        <div className={styles.stepperWrapper}>
            <StepsContent
                imgSrc={womanSVG}
                imgAlt={"woman with mask SVG"}
                //spanText={"Before scanning SH/FH measurements"}
                spanText={ulTitle}
                ulContent={ulContent}
            />
            <div className={styles.stepsAndCtaBtn}>
                {/* <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={activeStep}
                    // sx={{ backgroundColor: "transparent" }}
                    sx={{
                        backgroundColor: "transparent",
                        "& .MuiMobileStepper-dot": {
                            backgroundColor: '#B2B2B2',
                            width: 6,
                            height: 6
                        },
                        "& .MuiMobileStepper-dotActive": {
                            backgroundColor: 'white',
                            width: 8,
                            height: 8
                        },
                    }}
                /> */}
                {ctaButton}
            </div>
        </div>
    );
};

export default OnboardingStepper;

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext
} from "react";
import leftRestangle from "../../assets/leftRectangle.svg";
import { ReactComponent as ArrowUp } from "../../assets/arrowUp.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as Vector } from "../../assets/vector711.svg";
import styles from "./ShResultAdjustCard.module.css";
import { ReactComponent as CanvasOverlay } from "../../assets/canvasOverlay.svg";
import { Button } from "@walmart-web/livingdesign-components";
import { Card, Divider } from "@walmart-web/livingdesign-components";
import { useNavigate } from "react-router-dom";
import { drawShCanvas, changeShResult, clearShInterval } from "./ar";
import { stopApp } from "../../../public/API/pd_scan";
import screenfull from "screenfull";
import { startEvent } from "../../../public/API/pd_scan";
import { LanguageContext } from "../../LanguageContext";

//import { shEyeImage } from "./mock"; // !!! todo take from storage
//import { shEyeImage, pdResult, shResult } from "../adjustmentPage/mock";
//import { currentLanguage } from "../../i18n";

const ShResultAdjustCard = () => {
  const canvasRef = useRef();
  const navigate = useNavigate();

  const pdResult = useMemo(
    () => JSON.parse(localStorage.getItem("pdResult")),
    []
  );
  const shResult = useMemo(
    () => JSON.parse(localStorage.getItem("shResult")),
    []
  );
  const shEyeImage = useMemo(
    () => JSON.parse(localStorage.getItem("shEyeImage")),
    []
  );

  // useEffect(() => {
  //   if (!pdResult || !shResult || !shEyeImage) {
  //     screenfull.exit();
  //     stopApp();
  //   }
  // }, []);
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;
  useEffect(() => {
    console.log("Data after scan pd result:" + pdResult);
    console.log("Data after scan sh result:" + shResult);
    // if (shResult.leftEyeSh.mm > 20) canvasRef.current.style.height = "32vh";
    // else canvasRef.current.style.height = "35vh";
  }, []);

  const [selectedEye, setSelectedEye] = useState("left");

  const [results, setShResults] = useState({
    leftFH: shResult?.leftEyeSh.mm || 0,
    rightFH: shResult?.rightEyeSh.mm || 0,
    leftSH: shResult?.leftEyeOc.mm || 0,
    rightSH: shResult?.rightEyeOc.mm || 0,
  });

  const goSummary = () => {
    startEvent([['event_type', 'view_summary'], ['summary_type', 'sh'], ['pdResult', JSON.stringify(pdResult)], ['shResult', JSON.stringify(shResult)]]);
    navigate("/sh-result-page/sh");
  };

  useEffect(() => {
    console.log("test ", canvasRef.current);
    drawShCanvas(shEyeImage, shResult, canvasRef.current);
    // const canvas = canvasRef.current;
    // const context = canvas.getContext("2d");
    // const img = new Image();
    // img.src = shEyeImage;
    // img.onload = () => {
    //   const aspectRatio = img.height / img.width;
    //   const canvasWidth = canvasHeight / aspectRatio;
    //   canvas.width = canvasWidth;
    //   canvas.height = canvasHeight;
    //   context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
    // };
  }, []);

  const handleButtonClicked = (control, iDirection) => {
    const iEyeSide = selectedEye + control;
    changeShResult(
      iEyeSide,
      iDirection,
      shResult,
      canvasRef.current,
      setShResults,
      shEyeImage,
      pdResult
    );
  };

  // const handleButtonClicked = useCallback((control, iDirection) => {
  //   const iEyeSide = selectedEye + control;
  //   changeShResult(
  //     iEyeSide,
  //     iDirection,
  //     shResult,
  //     canvasRef.current,
  //     setShResults,
  //     shEyeImage,
  //     pdResult
  //   );
  // }, []);

  return (
    <>
      {/* <div className={styles.main}> */}
      {/* <div> */}
      <div className={styles.canvasWrapper}>
        <div className={styles.canvasContainer}>
          <canvas ref={canvasRef}></canvas>
          <div
            className={styles.canvasOverlay}
            //className={styles.canvasView}
            style={selectedEye === "left" ? { left: "50%" } : {}}
          />
        </div>
      </div>

      <div className={styles.controls}>
        <span className={styles.controlsHeader}>
          {/* Edit SH results using the buttons below */}
          {t.sh_result_title[currentLanguage]}
        </span>
        <div className={styles.controlBoxes}>
          <Card className={styles.eyeControlCard}>
            <div className={styles.controlBoxHeader}>
              <div className={styles.controlBoxHeaderLeft} onClick={() => setSelectedEye("left")} >
                <div
                  className={
                    (selectedEye === "left" && styles.selectedTab) || undefined
                  }
                  onClick={() => setSelectedEye("left")}
                >
                  {/* Left */}
                  {t.sh_left_tab[currentLanguage]}
                </div>
                {/* <div
                  className={styles.marker}
                  style={selectedEye === "left" ? { right: "100%" } : {}}
                ></div> */}
              </div>
              <div className={styles.controlBoxHeaderRight} onClick={() => setSelectedEye("right")} >
                <div
                  className={
                    (selectedEye === "right" && styles.selectedTab) || undefined
                  }
                  onClick={() => setSelectedEye("right")}
                >
                  {/* Right */}
                  {t.sh_right_tab[currentLanguage]}
                </div>
                {/* <div
                  className={styles.marker}
                  style={selectedEye === "right" ? { left: "100%" } : {}}
                ></div> */}
              </div>
              {/* <div
                className={styles.marker}
                style={selectedEye === "right" ? { left: "100%" } : {}}
              ></div> */}
            </div>
            <div
              className={styles.marker}
              // style={selectedEye === "right" ? { left: "50%" } : {}}
              style={selectedEye === "right" ? { alignSelf: "flex-end" } : {}}
            ></div>
            <div className={styles.controlBoxButtons}>
              <div className={styles.leftButton}>
                <ArrowLeft
                  onTouchStart={() => {
                    handleButtonClicked("Pupil", "left");
                  }}
                  onTouchEnd={() => clearShInterval(shResult, pdResult)}
                />
              </div>
              <div className={styles.verticalButtonsContainer}>
                <div className={styles.verticalButtons}>
                  <div className={styles.verticalContainer}>
                    <div className={styles.button} style={{ display: "flex" }}>
                      <ArrowUp
                        onTouchStart={() => {
                          handleButtonClicked("Pupil", "up");
                        }}
                        onTouchEnd={() => {
                          clearShInterval(shResult, pdResult);
                        }}
                      />
                    </div>
                    <div className={styles.verticalArrowSeparator}>
                      <Vector />
                    </div>
                    <div className={styles.button}>
                      <ArrowDown
                        onTouchStart={() => {
                          handleButtonClicked("Pupil", "down");
                        }}
                        onTouchEnd={() => clearShInterval(shResult, pdResult)}
                      />
                    </div>
                  </div>
                  <div>
                    {/* Pupil */}
                    {t.sh_pupil[currentLanguage]}
                  </div>
                </div>
                <div className={styles.verticalButtons}>
                  <div className={styles.verticalContainer}>
                    <div className={styles.button} style={{ display: "flex" }}>
                      <ArrowUp
                        onTouchStart={() => {
                          handleButtonClicked("Bottom", "up");
                        }}
                        onTouchEnd={() => clearShInterval(shResult, pdResult)}
                      />
                    </div>
                    <div className={styles.verticalArrowSeparator}>
                      <Vector />
                    </div>
                    <div className={styles.button}>
                      <ArrowDown
                        onTouchStart={() => {
                          handleButtonClicked("Bottom", "down");
                        }}
                        onTouchEnd={() => clearShInterval(shResult, pdResult)}
                      />
                    </div>
                  </div>
                  <div>
                    {/* Lower */}
                    {t.sh_lower_eye[currentLanguage]}
                  </div>
                </div>
                <div className={styles.verticalButtons}>
                  <div className={styles.verticalContainer}>
                    <div className={styles.button} style={{ display: "flex" }}>
                      <ArrowUp
                        onTouchStart={() => {
                          handleButtonClicked("Frame", "up");
                        }}
                        onTouchEnd={() => clearShInterval(shResult, pdResult)}
                      />
                    </div>
                    <div className={styles.verticalArrowSeparator}>
                      <Vector />
                    </div>
                    <div className={styles.button}>
                      <ArrowDown
                        onTouchStart={() => {
                          handleButtonClicked("Frame", "down");
                        }}
                        onTouchEnd={() => clearShInterval(shResult, pdResult)}
                      />
                    </div>
                  </div>
                  <div>
                    {/* Frame */}
                    {t.sh_frame[currentLanguage]}
                  </div>
                </div>
              </div>
              <div className={styles.rightButton}>
                <ArrowRight
                  onTouchStart={() => {
                    handleButtonClicked("Pupil", "right");
                  }}
                  onTouchEnd={() => clearShInterval(shResult, pdResult)}
                />
              </div>
            </div>
          </Card>
        </div>

        <div className={styles.footerContainer}>
          <div className={styles.controlsFooter}>
            <div className={styles.shfhContainer}>
              <span>
                {/* <strong>SH</strong> 29.7mm */}
                <strong>
                  {/* SH */}
                  {t.sh_lbl[currentLanguage]}
                </strong>
                {results.leftSH}
                {/* mm */}
                {t.unit[currentLanguage]}
              </span>
              <span>
                <strong>
                  {t.fh_lbl[currentLanguage]}
                </strong> {results.leftFH}
                {t.unit[currentLanguage]}
              </span>
            </div>
            <div className={styles.verticalSeparator}></div>
            <div className={styles.shfhContainer}>
              <span>
                <strong>{t.sh_lbl[currentLanguage]}</strong> {results.rightSH}
                {/* mm */}
                {t.unit[currentLanguage]}
              </span>
              <span>
                <strong>{t.fh_lbl[currentLanguage]}</strong> {results.rightFH}
                {/* mm */}
                {t.unit[currentLanguage]}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button size="medium" className={styles.btnWrapper} onClick={goSummary}>
          {/* Summary */}
          {t.summary_button[currentLanguage]}
        </Button>
      </div>
      {/* </div> */}
    </>
  );
};

export default ShResultAdjustCard;

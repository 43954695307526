import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./ResultPageContent.module.css";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Button } from "@walmart-web/livingdesign-components";

import {
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@walmart-web/livingdesign-components";
import { useNavigate, useParams } from "react-router-dom";
import * as Icon from "@livingdesign/icons";
import ResultCard from "./ResultCard";
//import { pdEyeImage, pdResult, shResult } from "../adjustmentPage/mock";
import { stopApp, startEvent } from "../../../public/API/pd_scan";
import screenfull from "screenfull";
//import { currentLanguage } from "../../i18n";
import { LanguageContext } from "../../LanguageContext";


const ResultPageContent = (props) => {
  const pdResult = JSON.parse(localStorage.getItem("pdResult"));
  const pdEyeImage = JSON.parse(localStorage.getItem("pdEyeImage"));
  const shResult = JSON.parse(localStorage.getItem("shResult"));

  const navigate = useNavigate();

  const [isDone, setIsDone] = useState(false);
  const currentLanguage = useContext(LanguageContext).appLanguage;
  console.log("result page language ", currentLanguage)
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;

  // useEffect(() => {
  //   if (isDone) {
  //     localStorage.removeItem("pdResult");
  //     localStorage.removeItem("pdEyeImage");
  //     localStorage.removeItem("shResult");
  //     localStorage.removeItem("shEyeImage");
  //     console.log("empty local storage");

  //   }
  // }, [isDone]);

  let { scantype } = useParams();


  console.log(

    "pdResult.leftEyePd.mm ",
    pdResult.leftEyePd.mm,
    " rightPD ",
    pdResult.rightEyePd.mm,
    "scantype ",
    scantype
  );
  const navigateToEdit = () => {
    {
      // mode === "sh"
      startEvent([["event_type", "back_to_adjust"]], ["summary_type", scantype]);
      scantype === "sh"
        ? navigate("/sh-result-adjustment")
        : navigate("/pd-result-adjustment");
    }
  };

  const navigationHanlder = () => {
    startEvent([['event_type', 'start_sh_intro']]);
    navigate("/prescansh");
  };


  const navigateHome = () => {
    screenfull.exit();

    localStorage.removeItem("pdResult");
    localStorage.removeItem("pdEyeImage");
    localStorage.removeItem("shResult");
    localStorage.removeItem("shEyeImage");
    console.log("empty local storage");
    //setIsDone(true)

    stopApp();
  };

  window.addEventListener("endSession", (event) => {
    if (event.detail) {
      navigate(`/`)
    }
  });

  //const { title, mode } = props;
  return (
    <div className={styles.content}>
      {/* <div className={styles.titleWrapper}>{title}</div> */}
      <div className={styles.titleWrapper}>
        {scantype === "sh" ? t.summary_title[currentLanguage] : t.summary_pd_title[currentLanguage]}
      </div>
      {/* <div className={styles.resultWrapper}> */}
      <div
        className={
          scantype === "sh" ? styles.resultShWrapper : styles.resultWrapper
        }
        style={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <ResultCard
          // title={"Pupillary Distance"}
          title={t.summary_pd_result_title[currentLanguage]}
          rightLabel={t.summary_pd_right[currentLanguage]}
          // rightValue={pdResult.rightEyePd.mm + "mm"}
          rightValue={pdResult.rightEyePd.mm + t.unit[currentLanguage]}
          leftLabel={t.summary_pd_left[currentLanguage]}
          leftValue={pdResult.leftEyePd.mm + t.unit[currentLanguage]}
          // leftValue={pdResult.leftEyePd.mm + "mm"}

          sumResult={
            (
              parseFloat(pdResult.rightEyePd.mm) +
              parseFloat(pdResult.leftEyePd.mm)
            ).toFixed(1) + " " + t.unit[currentLanguage]}
        // ).toFixed(1) + "mm"

        />
        {scantype === "sh" && (
          <>
            <ResultCard
              // title={"Fitting Height"}
              title={t.fh_result_sub_title[currentLanguage]}
              rightLabel={t.summary_fh_right[currentLanguage]}
              //rightValue={shResult.rightEyeOc.mm + "mm"}
              rightValue={shResult.rightEyeSh.mm + t.unit[currentLanguage]}
              leftLabel={t.summary_fh_left[currentLanguage]}
              //leftValue={shResult.leftEyeOc.mm + "mm"}
              leftValue={shResult.leftEyeSh.mm + t.unit[currentLanguage]}
            />
            <ResultCard
              title={t.sh_result_sub_title[currentLanguage]}
              rightLabel={t.summary_sh_right[currentLanguage]}
              //rightValue={shResult.rightEyeSh.mm + "mm"}
              rightValue={shResult.rightEyeOc.mm + t.unit[currentLanguage]}
              leftLabel={t.summary_sh_left[currentLanguage]}
              // leftValue={shResult.leftEyeSh.mm + "mm"}
              leftValue={shResult.leftEyeOc.mm + t.unit[currentLanguage]}
            />
          </>
        )}
      </div>

      <div>
        {scantype === "sh" ? (
          <div className={styles.buttonContainer}>
            <Button
              size="small"
              className={styles.btnWrapper}
              onClick={navigateHome}
            >
              {t.finish_session[currentLanguage]}
            </Button>
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              size="small"
              className={styles.ctaBtn}
              onClick={navigateHome}
            >
              {/* Done */}
              {t.finish_session[currentLanguage]}
            </Button>
            <Button
              size="small"
              className={styles.btnWrapper}
              onClick={navigationHanlder}
            >
              {/* Start SH/FH */}
              {t.start_sh_scan[currentLanguage]}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.editWrapper}>
        <KeyboardArrowLeft size="medium" />
        <p onClick={navigateToEdit}>
          <u>
            {/* Back to Edit */}
            {t.back_to_edit[currentLanguage]}
          </u>
        </p>
      </div>
    </div>
  );
};

export default ResultPageContent;

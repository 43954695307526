import React from "react";
import Header from "../components/Header";
import { useRescan } from "../hooks/useRescan";
import ShResultAdjustCard from "../components/adjustmentPage/ShResultAdjustCard";
import { Refresh } from "@livingdesign/icons";

const ShResultAdjust = () => {
  const navigateHanldler = useRescan("sh")

  return (
    <>
      <Header
        rescan={true}
        scanType={"sh"}
        rightButtons={[<Refresh size="medium" style={{pointerEvents: "none"}}/>]}
        leftBottons={[]}
        navigateFunction={() => navigateHanldler("sh")}

      />
      <ShResultAdjustCard />
    </>
  );
};

export default ShResultAdjust;

import React, { useState, useMemo, useContext } from "react";
import manPortrait from "../../assets/manPortrait.svg";
import styles from "./ManualPageContent.module.css";
import { Button } from "@walmart-web/livingdesign-components";
import PdManualCard from "./PdManualCard.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from '../../LanguageContext';
import { startSessionOuterScope, startApp, startEvent } from '../../../public/API/pd_scan';

const ManualPageContent = () => {
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const [rightPD, setRightPD] = useState();
  const [leftPD, setLeftPD] = useState();
  const navigate = useNavigate();

  const total = parseInt(rightPD) + parseInt(leftPD);
  var isRightValid = false;
  var isLeftValid = false;

  if (
    rightPD &&
    Number(rightPD.slice(0, 2)) > 20 &&
    Number(rightPD.slice(0, 2)) < 45
  ) {
    isRightValid = true;
  }
  if (
    leftPD &&
    Number(leftPD.slice(0, 2)) > 20 &&
    Number(leftPD.slice(0, 2)) < 45
  ) {
    isLeftValid = true;
  }
  const isFormFilled = leftPD && rightPD && isRightValid && isLeftValid;
  console.log("isRightValid", isRightValid, "isLeftValid ", isLeftValid);

  const navigationHanlder = () => {
    const pdResult = {};
    pdResult.PDmm = Number(rightPD + leftPD).toFixed(1);
    pdResult.leftEyePd = { mm: String(leftPD), px: 0 };
    pdResult.rightEyePd = { mm: String(rightPD), px: 0 };

    localStorage.setItem("pdResult", JSON.stringify(pdResult));
    console.log("PD manual results ", pdResult)
    navigate("/prescansh");
    // startSessionOuterScope()
  };

  const startHandler = async () => {
    startEvent([['event_type', 'next_pd_text']]);
    //setIsLoading(true)
    //await requestAccess();

    startSessionOuterScope()
  };

  return (
    <div className={styles.content}>
      <div className={styles.upperContent}>
        <img src={manPortrait} alt="main-portrait" />
        <PdManualCard
          rightPD={rightPD}
          leftPD={leftPD}
          setRightPD={setRightPD}
          setLeftPD={setLeftPD}
        // rightFieldError={setRightFieldError}
        // leftFieldError={setLeftFieldError}
        />
      </div>
      {/* <div className={styles.buttonContainer}> */}
      <Button
        disabled={!isFormFilled}
        size="medium"
        className={styles.btnWrapper}
        onClick={navigationHanlder}
      // onClick={startHanlder}
      // onClick={() => { startHanlder() }}
      >
        {t.start_sh_scan[currentLanguage]}
        {/* Start SH/FH */}
      </Button>
      {/* </div> */}
    </div>
  );
};

export default ManualPageContent;

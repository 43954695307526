import React, { useEffect, useState, useRef, useMemo } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion } from "framer-motion";
import { Button } from "@walmart-web/livingdesign-components";
import styles from "./LoadingUI.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { startPDScanOuterScope } from "../../../public/API/pd_scan";
import LoadingCompUI from "../../components/scanloading/LoadingCompUI";
import ProgressComp from "../../components/scanloading/ProgressComp";
import ErrorHandler from "../../components/ErrorHandler";
import { useRescan } from '../../hooks/useRescan'
import { getScanParams } from '../../../public/API/pd_scan.js'

export default function CircleProgressBarBasec(props) {
  let { scantype } = useParams();
  const [counter, setCounter] = useState(10);
  const [progress, setProgress] = useState("");
  const [isScanComplete, setIsScanComplete] = useState(false);
  const [numberOfScans, setNumberOfScans] = useState("");
  const [isProgressStart, setProgressStart] = useState(false);
  const [isReadyToStart, setIsReadyToStart] = useState(false);
  const [errorHandler, setErrorHandler] = useState(null);
  let navigate = useNavigate();


  useEffect(() => {
    if (isReadyToStart && counter > 1) {
      setTimeout(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }
  }, [isReadyToStart, counter]);

  useEffect(() => {
    console.log(props.vectorsData)

    if (counter === 1) {
      setIsReadyToStart(false);
      props.configurationUIToggle(false)
    //   setProgressStart(true);
      ////navigate
      navigate(`/loading/${scantype}`)
    }
  }, [counter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scanHandler()
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const scanHandler = () => {
    props.configurationUIToggle(true)
    setIsReadyToStart(true);
    startPDScanOuterScope();
  };

  window.addEventListener("scanParams", (event) => {
    setCounter(event.detail)
  });

  window.addEventListener("progressDetail", (event) => {
    setProgress(event.detail.progress)
    setNumberOfScans(event.detail.asyncScans)
  });

  window.addEventListener("scanComplete", (event) => {
    if (event.detail) {
      setIsScanComplete(true)
    }
  });

//   useEffect(() => {
//     if (isScanComplete && !errorHandler) {
//     //   console.log("before navcigation" + new Date().getTime())
//       document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;
//       navigate(`/${scantype}-result-adjustment`);
//     }
//   }, [isScanComplete]);

  window.addEventListener("cameraError", (event) => {
    // console.log("error!!!" + event.detail)
    setErrorHandler(event.detail);
  });

//   const showCamera = () => {
//     document.querySelector("#divCanvas").style.cssText = `display: flex;`;
//     document.querySelector("#webGLCanvas").style.cssText = `
//        z-index: 1; 
//        position: absolute;
//        width: 100%;
//        top: -200px;
//        transform: scaleX(-1)
//    `; // window.location.reload();
//   };

//   useEffect(() => {
//     showCamera();
//   }, []);



  return (
    <>
      {errorHandler ?
        <ErrorHandler scantype={scantype} errormessage={errorHandler} /> :
        // isProgressStart ?
        //   <ProgressComp 
        //   scantype={scantype}
        //     progress={progress}
        //     numberOfScans={numberOfScans}
        //   />
        //   :
          <LoadingCompUI
            scanHandler={scanHandler}
            isReadyToStart={isReadyToStart}
            counter={counter}
            scantype={scantype}
            
          />}
    </>
  )
}

import React, { useEffect } from "react";
import Content from "../components/Content";
import { ContentOrStoreSelector } from "../components/ContentOrStoreSelector";
import Header from "../components/Header";
import LanguageSelector from "../components/LanguageSelector";
import { Icon, InfoCircle, Globe } from "@livingdesign/icons";
import styles from "./Root.module.css";

export default function Root() {

  return (
    <>
      <Header
        rescan={true}
        scanType={"pd"}
        // rightButtons={[]}
        rightButtons={[<LanguageSelector />]}
        // rightButtons={[<Globe size="large" />]}
        leftBottons={[]}
        navigateFunction={() => { console.log("place for action function") }}
      />
      {/* <LanguageSelector /> */}
      <ContentOrStoreSelector />
    </>
  );
}

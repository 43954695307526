import React, { useEffect, useState, useMemo, useContext } from "react";
import styles from "./ProgressComp.module.css";
import darkgreenCheck from "../../assets/darkgreenCheck.svg";
import backgroundProgressCircle from "../../assets/backgroundProgressCircle.svg";
import { ProgressIndicator } from "@walmart-web/livingdesign-components";
import { useNavigate, useParams } from "react-router-dom";
import ErrorHandler from "../ErrorHandler";
//import { currentLanguage } from "../../i18n";
import { LanguageContext } from "../../LanguageContext";

const ProgressComp = (props) => {
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;

  let { scantype } = useParams();
  const [progress, setProgress] = useState("");
  // const [numberOfScans, setNumberOfScans] = useState("");
  const [isScanComplete, setIsScanComplete] = useState(false);
  const [errorHandler, setErrorHandler] = useState(null);
  let navigate = useNavigate();



  useEffect(() => {
    if (isScanComplete && !errorHandler) {
      //   console.log("before navcigation" + new Date().getTime())
      document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;
      navigate(`/${scantype}-result-adjustment`);
    }
  }, [isScanComplete]);


  window.addEventListener("scanComplete", (event) => {
    if (event.detail) {
      setIsScanComplete(true)
    }
  });

  window.addEventListener("progressDetail", (event) => {
    setProgress(event.detail.progress)
    // setNumberOfScans(event.detail.asyncScans)
  });

  window.addEventListener("cameraError", (event) => {
    // console.log("error!!!" + event.detail)
    setErrorHandler(event.detail);
  });

  return (
    <>
      {errorHandler ?
        <ErrorHandler scantype={scantype} errormessage={errorHandler} /> :
        <div className={styles.main}>
          <div className={styles.overlaywrapper}>
            <div className={styles.progressWrapper}>
              <img className={styles.backgroundCircle} src={backgroundProgressCircle} alt="progressbar3" />
              <section className={styles.progressContent}>
                <img src={darkgreenCheck} alt="darkgreenCheck" />
                <h1>
                  {/* Scan Completed */}
                  {t.scan_completed[currentLanguage]}
                </h1>
                <h5>
                  {/* Analyzing data */}
                  {t.analyzing[currentLanguage]}
                </h5>
                <div className={styles.progressBar}>
                  <ProgressIndicator
                    label={<span style={{ display: "none" }}>%</span>} //must have some value
                    value={progress}
                    variant="info"
                    max={100}
                  />
                </div>
                <span>{progress}%</span>
              </section>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ProgressComp;

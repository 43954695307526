import React from 'react'
import Header from "../components/Header";
import PdManualPageContent from "../components/pdmanualPage/ManualPageContent";

const PdManual = () => {

  return (
    <>
      <Header
        rescan={false}
        scanType={""}
        rightButtons={[]}
        leftBottons={[]}
        navigateFunction={() => { console.log("place for action function") }}
      />
      <PdManualPageContent />
      {/* <div style={{height: "500px"}}>Ipad keyboard</div> */}
    </>
  );
}



export default PdManual
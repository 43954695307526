import React from "react";
import Header from "../components/Header";
import ResultAdjustmentCard from "../components/adjustmentPage/ResultAdjustmentCard";
import { useRescan } from "../hooks/useRescan";
import { Refresh } from "@livingdesign/icons";

const ResultAdjustment = () => {
  const navigateHanldler = useRescan("pd")

  return (
    <>
      <Header
        rescan={true}
        scanType={"pd"}
        rightButtons={[<Refresh size="medium" style={{ pointerEvents: "none" }} />]}
        leftBottons={[]}
        navigateFunction={() => navigateHanldler("pd")}
      />
      <ResultAdjustmentCard />
    </>
  );
};

export default ResultAdjustment;

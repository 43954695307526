import React from "react";
import step1LevelLines from "../../assets/step1LevelLines.svg";
import styles from "./DeviceAngle.module.css";
// import { Display } from "@walmart-web/livingdesign-components";
import step1Approve from "../../assets/step1Approve.svg";
import { Check } from "@livingdesign/icons";
import { startPDScanOuterScope } from "../../../public/API/pd_scan";
import { Link } from "react-router-dom";

const DeviceAngle = ({ angle, thresholdMin, thresholdMax }) => {
  const normalizedAngle = angle - 90;
  // const normalizedAngle = 0;
  const isInRange = thresholdMin <= angle && angle <= thresholdMax;
  const checkIcon = (
    <Check
      style={{
        fontSize: "1.2em",
      }}
      color="black"
    />
  );

  // const testBtn = () => {
  //   startPDScanOuterScope();
  // };

  return (
    <>
      <div className={styles.centerWrapper}>
        <h1
          style={{ color: "black", zIndex: 1, position: "relative", top: 30 }}
        >
          {isInRange ? (
            <Check
              style={{
                fontSize: "1.2em",
              }}
              color="black"
            />
          ) :
            <>
          {normalizedAngle}
          &deg;
            </>
          }

        </h1>
        <img
          className={styles.middleCircle}
          alt="step1Approve"
          src={step1Approve}
        />
        <img
          className={styles.levelLines}
          alt="step1LevelLines"
          src={step1LevelLines}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 200,
          height: angle ? `${angle - 49}vh` : 0,
          width: "100vw",
          backgroundColor: isInRange ? "#FFF200B2" : "#FFFFFFB2",
        }}
      >

      </div>
    </>
  );
};

export default DeviceAngle;

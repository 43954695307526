import React, { useMemo, useContext } from 'react';
import styles from "./StorePicker.module.css";
import { Button } from "@walmart-web/livingdesign-components";
import storefront from "../assets/storefront_circle.svg";
import close from "../assets/close.svg";
import { Pencil } from "@livingdesign/icons";
import { Backdrop } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import data from "./storelist.json";
//import { currentLanguage } from "../i18n";
import { LanguageContext } from '../LanguageContext';



export const StorePicker = ({ onClose }) => {
    // const currentLanguage = useMemo(
    //     () => localStorage.getItem('appLanguage'),
    //     []
    // );
    const currentLanguage = useContext(LanguageContext).appLanguage;
    console.log("Store picker ", currentLanguage, useContext(LanguageContext))
    const settingsData = useMemo(
        () => JSON.parse(localStorage.getItem("settingsData")),
        []
    );
    const t = settingsData.languageText;
    const storeID = localStorage.getItem('selectedStoreID');
    const visitCount = localStorage.getItem('visitCount');
    const Stores = data;
    const targetStore = Stores.find((store) => store.Id === Number(storeID));
    console.log("targetStore ", targetStore)
    const navigate = useNavigate();
    console.log("Store picker visitCount ", visitCount);

    const handleChangeClick = () => {
        {
            //localStorage.removeItem("selectedStoreID");
            localStorage.setItem('visitCount', 1);
            //navigate("/");
            window.location.reload()
        }
    };
    return <Backdrop open onClick={onClose}>
        <div onClick={(event) => event.stopPropagation()} className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}><strong>
                    {/* Vision Center number */}
                    {t.store_title[currentLanguage]}
                </strong>
                </div>
                <div onClick={onClose} className={styles.closeButton}> <img src={close} alt="close" />
                </div>
            </div>

            <div className={styles.storeIcon}>
                <img src={storefront} style={{ height: 145, width: 145 }} alt="store" />
            </div>
            <div className={styles.storeDetails}>

                {/* <div>VC{storeID}</div> */}
                <div>{t.vc_tag[currentLanguage]}{storeID}</div>
                <div className={styles.separator}></div>
                <div>{targetStore.Name}</div>
            </div>
            <div className={styles.buttonContainer}>

                <Button
                    className={styles.btnWrapper}
                    variant="primary"
                    onClick={handleChangeClick}
                >
                    <Pencil size="small" />
                    {/* <strong>Change</strong> */}
                    <strong>{t.change_store[currentLanguage]}</strong>
                </Button>
            </div>
        </div>
    </Backdrop>
}
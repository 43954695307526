
import React, { useEffect } from "react";
import Header from "../components/Header";
import ResultPageContent from "../components/resultPage/ResultPageContent";
import { useRescan } from "../hooks/useRescan";
import { Refresh } from "@livingdesign/icons";
import { useNavigate, useParams } from "react-router-dom";

export default function ResultSummary(props) {
  let { scantype } = useParams();
  const navigateHanldler = useRescan(scantype)


  return (
    <>
      <Header
        rescan={true}
        scanType={scantype}
        rightButtons={[<Refresh size="medium" style={{pointerEvents: "none"}}/>]}
        leftBottons={[]}
        navigateFunction={() =>  navigateHanldler(scantype)}

      />
      <ResultPageContent {...props} />
    </>
  );
}

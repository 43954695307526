import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import styles from "./LoadingCompUI.module.css";
import { CircularProgressbar } from "react-circular-progressbar";
import { motion } from "framer-motion";
import { Button } from "@walmart-web/livingdesign-components";
import guidancePD from '../../assets/guidancePD.svg'
import { Display } from "@walmart-web/livingdesign-components";
import ipadRearCamera from '../../assets/ipadRearCamera.svg'
//import { currentLanguage } from "../../i18n";
import { LanguageContext } from "../../LanguageContext";

const LoadingComp = (props) => {
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );

  const t = settingsData.languageText
  const { scantype, isReadyToStart, counter } = props

  // const boxTest = (scantype === "pd" ? "Raise your eyebrows and look at the camera lens" : "Look at the camera lens and hold your natural pose")
  const boxTest = (scantype === "pd" ? t.before_pd_scan[currentLanguage] : t.before_sh_scan[currentLanguage])

  const counterUI = (isReadyToStart && counter > 1) && (
    <div className={styles.controlsWrapper}>
      <div className={styles.controlsCircle}>
        <CircularProgressbar value={0} text={counter} />
      </div>
    </div>
  )

  const scanningLog = (<div className={styles.logArea} style={{ zIndex: 2 }}>
    <div className={styles.logContent}>

      <Display as="h1" size="medium" weight={700}>
        {/* Scanning... */}
        {t.scanning[currentLanguage]}
      </Display>

    </div>
  </div>)

  return (

    <div className={styles.main}>

      {!isReadyToStart &&
        <div className={styles.popUp} >
          <img src={ipadRearCamera} alt="ipadRearCamera" />
          <h1> {boxTest}</h1>
        </div>}
      {isReadyToStart && counterUI}

      {/* {isReadyToStart && scanningLog} */}
    </div>

  )
}

export default LoadingComp
import { useCallback, useEffect, useState } from 'react';

// type DeviceOrientation = {
//   alpha: number | null,
//   beta: number | null,
//   gamma: number | null,
// }

// type UseDeviceOrientationData = {
//   orientation: DeviceOrientation | null,
//   error: Error | null,
//   requestAccess: () => Promise<boolean>,
//   revokeAccess: () => Promise<void>,
// };

export const useDeviceOrientation= ()=> {
  const [error, setError] = useState(null);
  const [orientation, setOrientation] = useState({
    alpha: 0,
    beta:0,
    gamma: 0,
  });

  const onDeviceOrientation = (event) => {
    setOrientation({
      alpha: event.alpha,
      beta: event.beta,
      gamma: event.gamma,
    });
  };

  const revokeAccessAsync = async () => {
    window.removeEventListener('deviceorientation', onDeviceOrientation);
    setOrientation(null);
  };

  const requestAccessAsync = async () => {
    if (!DeviceOrientationEvent) {
      setError(new Error('Device orientation event is not supported by your browser'));
      return false;
    }

    if (
      DeviceOrientationEvent.requestPermission
      && typeof DeviceMotionEvent.requestPermission === 'function'
    ) {
      let permission;
      try {
        permission = await DeviceOrientationEvent.requestPermission();
      } catch (err) {
        setError(err);
        return false;
      }
      if (permission !== 'granted') {
        setError(new Error('Request to access the device orientation was rejected'));
        return false;
      }
    }

    window.addEventListener('deviceorientation', onDeviceOrientation);

    return true;
  };

  const requestAccess = useCallback(requestAccessAsync, []);
  const revokeAccess = useCallback(revokeAccessAsync, []);

  useEffect(() => {
    return ()=> {
      revokeAccess();
    };
  }, [revokeAccess]);

  return {
    orientation,
    error,
    requestAccess,
    revokeAccess,
  };
};
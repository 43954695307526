import { useEffect } from "react";
import styles from "./PreScan.module.css";
// import Webcam from "react-webcam";
import { Pencil } from "@livingdesign/icons";
import OnboardingStepper from "../components/OnboardingStepper";
import OnboardingSh from "../components/OnboardingSh";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PreScan() {
  let navigate = useNavigate();

  //webcam lib
  // const videoConstraints = {
  //   facingMode: "user",
  //   // facingMode: { exact: "environment" }
  // };

  // const stylingObj = {
  //   // height: "100vh",
  //   width: "100%"
  //   // transform: "translate(-25%)",
  // };

  const hideCamera = () => {
    // document.querySelector("#divCanvas").style.cssText = `display: flex;`;
    document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;
  };

  const navigateHome = () => {
    document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;
    // window.location.reload();
    navigate("/pdmanual-page");
  };

  return (
    <div className={styles.main}>
      <div className={styles.overlaywrapper}>
        {/* <OnboardingStepper /> */}
        <OnboardingSh />

        {/* <Link to={"/"} style={{ textDecoration: "none" }}> */}
        {/* <Link to={"/pdmanual-page"}> */}
        <p className={styles.wtchBtn}>

        </p>
        {/* </Link> */}
      </div>
    </div>
  );
}

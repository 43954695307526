import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import styles from "./CameraInstruction.module.css";
import { Display } from "@walmart-web/livingdesign-components";
import Distance from "../components/configuration/Distance";
import CenterFace from "../components/configuration/CenterFace";
import FaceDirection from "../components/configuration/FaceDirection";
import DeviceAngle from "../components/configuration/DeviceAngle";
import { useDeviceOrientation } from "../hooks/useOrientation";
import { useNavigate, useParams } from "react-router-dom";
// import { startPDScanOuterScope } from "../../public/API/pd_scan";
import { Spinner } from "@walmart-web/livingdesign-components";
import LoadingUI from "../components/scanloading/LoadingUI";
//import { currentLanguage } from "../i18n.js";
import { startEvent } from '../../public/API/pd_scan'
import { LanguageContext } from '../LanguageContext';
import data from '../assets/data.json'

// const thresholds = [
//   {
//     id: 1,
//     name: "deviceAngle",
//     baseRange: { min: 88, max: 92 },
//     wideRange: { min: 87, max: 93 },
//   },
//   {
//     id: 2,
//     name: "faceDistance",
//     baseRange: { min: 45, max: 55 },
//     wideRange: { min: 35, max: 70 },
//   },
//   {
//     id: 3,
//     name: "faceCentered",
//     baseRange: { axis: { x: { min: -20, max: 20 }, y: { min: -40, max: 40 } } },
//     wideRange: { axis: { x: { min: -40, max: 40 }, y: { min: -60, max: 60 } } },
//   },
//   {
//     id: 4,
//     name: "faceDirection",
//     baseRange: {
//       axis: { x: { min: -170, max: 170 }, y: { min: -350, max: -30 } },
//     },
//     wideRange: {
//       axis: { x: { min: -170, max: 170 }, y: { min: -380, max: 20 } },
//     },
//   },
//   {
//     id: 5,
//     name: "guidance_valid"
//   }
// ];

// const livePositionResultCount = {
//   "inScreen": 10,
//   "backgroundCheck": 50,
//   "onload": 5
// }

const CameraInstruction = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [vectorsData, setVectorsData] = useState([0, 0, 0, 0]);
  const { orientation, requestAccess } = useDeviceOrientation({ beta: 0 });
  const [timer, setTimer] = useState(30);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTimer, setLoadingTimer] = useState(0);
  const [isReadyToScan, setIsReadyToScan] = useState(false);
  const [isUiVisible, setUiVisible] = useState(true);
  const deviceAngleLiveDataArray = useRef([]);
  const faceDistanceLiveDataArray = useRef([]);
  const faceCenteredXLiveArray = useRef([]);
  const faceCenteredYLiveArray = useRef([]);
  const faceDirectionXLiveArray = useRef([]);
  const faceDirectionYLiveArray = useRef([]);

  const thresholds = data.thresholds
  const livePositionResultCount = data.livePositionResultCount
  // console.log(livePositionResultCount)


  const pointsCounter = useRef(0);
  const navigate = useNavigate();
  let { scantype } = useParams();

  window.addEventListener("vectorsData", (event) => {
    setVectorsData(event.detail);
  });

  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );

  const t = settingsData.languageText

  useEffect(() => {
    document.querySelector("#divCanvas").style.cssText = `display: flex;`;
    document.querySelector("#webGLCanvas").style.cssText = `
       z-index: 1; 
       position: absolute;
       width: 100%;
       top: -200px;
       transform: scaleX(-1)
   `;
  }, []);

  useEffect(() => {
    deviceAngleLiveDataArray.current.unshift(Math.trunc(orientation.beta));
  },);

  useEffect(() => {
    vectorsData[4] && faceDistanceLiveDataArray.current.unshift(vectorsData[4]);
  });

  useEffect(() => {
    vectorsData[2] && faceCenteredXLiveArray.current.unshift(vectorsData[2]);
  },);

  useEffect(() => {
    vectorsData[3] && faceCenteredYLiveArray.current.unshift(vectorsData[3]);
  },);

  useEffect(() => {
    vectorsData[1] && faceDirectionXLiveArray.current.unshift(vectorsData[1]);
  },);

  useEffect(() => {
    vectorsData[0] && faceDirectionYLiveArray.current.unshift(vectorsData[0]);
  },);

  useEffect(() => {
    startEvent([
      ['event_type', 'guidance_screen'],
      ['screen_type', thresholds[activeStep - 1].name],
      ['scan_type', scantype],
      ['scan_active', isReadyToScan]
    ]);
  }, [activeStep])

  useEffect(() => {
    // checks current step with inScreen threshold, if within threshold then increase point every 500 ms
    if (!isLoading) {
      pointsCounter.current = 0;
      const statusInterval = setInterval(() => {
        // console.log("checking interval in step" + activeStep)
        switch (activeStep) {
          case 1:

            isDeviceAngleInRange("inScreen")
              ? ++pointsCounter.current
              : (pointsCounter.current = 0);
            break;
          case 2:

            isFaceDistanceInRange("inScreen")
              ? ++pointsCounter.current
              : (pointsCounter.current = 0);
            break;
          case 3:

            isFaceCenteredInRange("inScreen")
              ? ++pointsCounter.current
              : (pointsCounter.current = 0);
            break;
          case 4:

            isFaceDirectiondInRange("inScreen")
              ? ++pointsCounter.current
              : (pointsCounter.current = 0);
            break;
          case 5:
            if (!isDeviceAngleInRange("inScreen")) { setActiveStep(1); return; }
            if (!isFaceDistanceInRange("inScreen")) { setActiveStep(2); return; }
            if (!isFaceCenteredInRange("inScreen")) { setActiveStep(3); return; }
            if (!isFaceDirectiondInRange("inScreen")) { setActiveStep(4); return; }
            break;
          default:
            break;
        }
      }, 500);

      return () => clearInterval(statusInterval);
    }
  }, [activeStep, isLoading]);

  const isDeviceAngleInRange = (rangeType) => {
    let numberOfItemsFromArray = livePositionResultCount[rangeType]
    const calculatedAverage = calcAverage(deviceAngleLiveDataArray.current, numberOfItemsFromArray);
    let isInRange = thresholds[0].baseRange.min <= calculatedAverage && calculatedAverage <= thresholds[0].baseRange.max;
    return isInRange;
  };

  const isFaceDistanceInRange = (rangeType) => {
    let numberOfItemsFromArray = livePositionResultCount[rangeType]
    const calculatedAverage = calcAverage(faceDistanceLiveDataArray.current, numberOfItemsFromArray);
    let isInRange = thresholds[1].baseRange.min <= calculatedAverage && calculatedAverage <= thresholds[1].baseRange.max;
    return isInRange;
  };

  const isFaceCenteredInRange = (rangeType) => {
    let numberOfItemsFromArray = livePositionResultCount[rangeType]

    const calculatedAverageXaxis = calcAverage(faceCenteredXLiveArray.current, numberOfItemsFromArray);
    const calculatedAverageYaxis = calcAverage(faceCenteredYLiveArray.current, numberOfItemsFromArray);

    //x axis basic ranges
    let xAxisMinRange = thresholds[2].baseRange.axis.x.min;
    let xAxisMaxRange = thresholds[2].baseRange.axis.x.max;
    let isXaxisInRange = xAxisMinRange <= calculatedAverageXaxis && calculatedAverageXaxis <= xAxisMaxRange;

    //y axis basic ranges
    let yAxisMinRange = thresholds[2].baseRange.axis.y.min;
    let yAxisMaxRange = thresholds[2].baseRange.axis.y.max;
    let isYaxisInRange = yAxisMinRange <= calculatedAverageYaxis && calculatedAverageYaxis <= yAxisMaxRange;

    let isInRange = isXaxisInRange && isYaxisInRange;
    return isInRange;
  };

  const isFaceDirectiondInRange = (rangeType) => {
    let numberOfItemsFromArray = livePositionResultCount[rangeType]
    const calculatedAverageXaxis = calcAverage(faceDirectionXLiveArray.current, numberOfItemsFromArray);
    const calculatedAverageYaxis = calcAverage(faceDirectionYLiveArray.current, numberOfItemsFromArray);

    //x axis basic ranges
    let xAxisMinRange = thresholds[3].baseRange.axis.x.min;
    let xAxisMaxRange = thresholds[3].baseRange.axis.x.max;
    let isXaxisInRange = xAxisMinRange <= calculatedAverageXaxis && calculatedAverageXaxis <= xAxisMaxRange;

    //y axis basic ranges
    let yAxisMinRange = thresholds[3].baseRange.axis.y.min;
    let yAxisMaxRange = thresholds[3].baseRange.axis.y.max;
    let isYaxisInRange = yAxisMinRange <= calculatedAverageYaxis && calculatedAverageYaxis <= yAxisMaxRange

    let isInRange = (scantype === "sh") ? isXaxisInRange : (isXaxisInRange && isYaxisInRange);
    return isInRange;
  };

  // useEffect(() => {
  //   navigateHandler();
  // }, []);

  const isAllStepsValid = () => {
    let isValid =
      isDeviceAngleInRange("onload") &&
      isFaceDistanceInRange("onload") &&
      isFaceCenteredInRange("onload") &&
      isFaceDirectiondInRange("onload");
    return isValid;
  };

  useEffect(() => {
    // on first load check steps in "onload" threshold and when loading is done checks if all steps are ready
    if (!isLoading && isAllStepsValid()) {
      navigateHandler();
    } else {
      if (!isDeviceAngleInRange("onload")) { setActiveStep(1); return; }
      if (!isFaceDistanceInRange("onload")) { setActiveStep(2); return; }
      if (!isFaceCenteredInRange("onload")) { setActiveStep(3); return; }
      if (!isFaceDirectiondInRange("onload")) { setActiveStep(4); return; }
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("COUNTER:" + pointsCounter.current + " STEP:" + activeStep)
    //listens to point for each step and ready checks other steps
    if (pointsCounter.current === 4) {
      switch (activeStep) {
        case 1:
          if (!isDeviceAngleInRange("inScreen")) { setActiveStep(1); return; }
          if (!isFaceDistanceInRange("backgroundCheck")) { setActiveStep(2); return; }
          if (!isFaceCenteredInRange("backgroundCheck")) { setActiveStep(3); return; }
          if (!isFaceDirectiondInRange("backgroundCheck")) { setActiveStep(4); return; }
          else { navigateHandler(); }
          break;

        case 2:
          if (!isDeviceAngleInRange("backgroundCheck")) { setActiveStep(1); return; }
          if (!isFaceDistanceInRange("inScreen")) { setActiveStep(2); return; }
          if (!isFaceCenteredInRange("backgroundCheck")) { setActiveStep(3); return; }
          if (!isFaceDirectiondInRange("backgroundCheck")) { setActiveStep(4); return; }
          else { navigateHandler(); }
          break;

        case 3:
          if (!isDeviceAngleInRange("backgroundCheck")) { setActiveStep(1); return; }
          if (!isFaceDistanceInRange("backgroundCheck")) { setActiveStep(2); return; }
          if (!isFaceCenteredInRange("inScreen")) { setActiveStep(3); return; }
          if (!isFaceDirectiondInRange("backgroundCheck")) { setActiveStep(4); return; }
          else { navigateHandler(); }
          break;

        case 4:
          if (!isDeviceAngleInRange("backgroundCheck")) { setActiveStep(1); return; }
          if (!isFaceDistanceInRange("backgroundCheck")) { setActiveStep(2); return; }
          if (!isFaceCenteredInRange("backgroundCheck")) { setActiveStep(3); return; }
          if (!isFaceDirectiondInRange("inScreen")) { setActiveStep(4); return; }
          else { navigateHandler(); }
          break;
      }

      pointsCounter.current = 0;
    }
  }, [pointsCounter.current]);


  useEffect(() => {
    if (isReadyToScan) {
      configurationUIToggle(false)
    }
  }, [isReadyToScan])

  const navigateHandler = () => {
    setActiveStep(5)
    setIsReadyToScan(true)
    // document.querySelector("#webGLCanvas").style.cssText = `z-index: 0;`;

    // navigate(`/loading/${scantype}`);
    // if (scantype === "pd") {
    //   navigate("/loading/pd");
    // }
    // if (scantype === "sh") {
    //   navigate("/loading/sh");
    // }
    // startPDScanOuterScope()
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoadingTimer((prevCounter) => prevCounter + 1);
      }, 1000);
    }
  }, [loadingTimer, isLoading]);

  useEffect(() => {
    if ((loadingTimer >= 2)) {
      setIsLoading(false);
      return
    }
  }, [loadingTimer, vectorsData[1]]);


  const configurationUIToggle = (isTrue) => {
    setUiVisible(isTrue)
  }

  useEffect(() => {
    requestAccess();
  }, []);

  const stepsData = [
    { logText: t.adjust_angle_lbl[currentLanguage] },
    { logText: t.adjust_distance_lbl[currentLanguage] },
    { logText: t.adjust_frame_lbl[currentLanguage] },
    { logText: t.aline_face_lbl[currentLanguage] },
    { logText: t.scanning[currentLanguage] },
  ];

  const configurationInfo =
    activeStep === 1
      ? orientation && `${orientation.beta.toFixed(0)} degree`
      : activeStep === 2
        ? `Distance from camera: ${vectorsData[4]}`
        : activeStep === 3
          ? `Xaxis ${vectorsData[2]} Yaxis: ${vectorsData[3]}`
          : activeStep === 4
            ? `Head left to right: xAxis: ${vectorsData[1]} Yaxis: ${vectorsData[0]}`
            : "";


  return (
    isLoading ?
      <div className={styles.overlaywrapper}>
        <div className={styles.loadingWrapper}>
          <Spinner color="white" size="large" />
          {t.camera_loading_lbl[currentLanguage]}
        </div>
      </div>

      :
      <div className={styles.main}>
        <div className={styles.configurationArea} style={{ zIndex: 2 }}>
          {isReadyToScan && <LoadingUI configurationUIToggle={configurationUIToggle} vectorsData={
            [{ "faceDistance": faceDistanceLiveDataArray },
            { "faceCenteredX": faceCenteredXLiveArray },
            { "faceCenteredY": faceCenteredYLiveArray },
            { "faceDirectionX": faceDirectionXLiveArray },
            { "faceDirectionY": faceDirectionYLiveArray }]
          } />}

          {(isUiVisible && activeStep === 1) && (
            <DeviceAngle
              angle={orientation ? Math.trunc(orientation.beta) : 90}
              thresholdMin={thresholds[0].baseRange.min}
              thresholdMax={thresholds[0].baseRange.max}
            />
          )}
          {isUiVisible && (activeStep === 2) && (
            <Distance
              vectorsData={vectorsData}
              thresholdMin={thresholds[1].baseRange.min}
              thresholdMax={thresholds[1].baseRange.max}
            />
          )}
          {isUiVisible && (activeStep === 3) && (
            <CenterFace
              vectorsData={vectorsData}
              thresholdXaxisMin={thresholds[2].baseRange.axis.x.min}
              thresholdXaxisMax={thresholds[2].baseRange.axis.x.max}
              thresholdYaxisMin={thresholds[2].baseRange.axis.y.min}
              thresholdYaxisMax={thresholds[2].baseRange.axis.y.max}
            />
          )}
          {isUiVisible && (activeStep === 4) && (
            <FaceDirection
              vectorsData={vectorsData}
              isShScan={(scantype === "sh")}
              thresholdXaxisMin={thresholds[3].baseRange.axis.x.min}
              thresholdXaxisMax={thresholds[3].baseRange.axis.x.max}
              thresholdYaxisMin={thresholds[3].baseRange.axis.y.min}
              thresholdYaxisMax={thresholds[3].baseRange.axis.y.max}
            />
          )}
        </div>

        {(isUiVisible && stepsData[activeStep - 1]) &&
          <div className={styles.logArea} style={{ zIndex: 2 }}>
            <div className={styles.logContent}>
              <Display as="h1" size="medium" weight={700}>
                {stepsData[activeStep - 1].logText}
                {/* {configurationInfo} */}
              </Display>
            </div>
          </div>
        }
      </div>

  );
};

export default CameraInstruction;

const calcAverage = (liveArray, numberOfItems) => {
  // takes the first N numbers of the array
  let firstNItems = liveArray.slice(0, numberOfItems);
  const sum = firstNItems.reduce((partialSum, followedItem) => partialSum + followedItem, 0);
  // returns one number which is the average of the first N numbers
  const calcAverage = sum / numberOfItems;
  return calcAverage;
};

import { useNavigate } from "react-router-dom";
import {reScanOuterScope} from '../../public/API/pd_scan'

export function useRescan(scanType) {
    const navigate = useNavigate();

    const navigateHanldler = (scanType) => {
        console.log(scanType)
        if (scanType === "sh") {
            localStorage.removeItem("shResult");
            localStorage.removeItem("shEyeImage");
            // console.log("SH local storage removed!")
          }

          if (scanType === "pd") {
            localStorage.removeItem("pdResult");
            localStorage.removeItem("pdEyeImage");
            // console.log("PD local storage removed!")
          }
        
          navigate(`/configuration/${scanType}`)
          reScanOuterScope(scanType)
    }


    return navigateHanldler ;
}
import React from "react";
import styles from "./Header.module.css";
import walmartSVG from "../assets/walmartLogo_blue.svg";
import dropdownSVG from "../assets/dropdown.svg";
import Content from "../components/Content";
// import * as Icon from "@livingdesign/icons";

const Header = ({ rescan, scanType, rightButtons, leftButtons, navigateFunction }) => {

  return (
    <div className={styles.header}>
      {leftButtons ? leftButtons.map((button, i) => {
        return (
          <span className={styles.btn} id={i}>
            {button}
          </span>
        )
      }) : <span className={styles.btn}> </span>}

      <img src={walmartSVG} alt="walmart logo" className={styles.logo} />
      <div className={styles.rightBtns} onClick={navigateFunction}>
        {rightButtons?.map((button, i) => {
          return (
            // <span className={styles.btn} id={i} > 
            <span id={i} >
              {button}
            </span>
          )
        })}

      </div>
    </div>
  );
};
<span className={styles.btn}>
</span>

export default Header;

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import resultImage from "../../assets/scan-results.png";
import arrowUp from "../../assets/arrowUp.svg";
import { ReactComponent as ArrowUp } from "../../assets/arrowUp.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import styles from "./ResultAdjustmentCard.module.css";
import { ReactComponent as CanvasOverlay } from "../../assets/canvasOverlay.svg";

import { Button } from "@walmart-web/livingdesign-components";
import { Card, Divider } from "@walmart-web/livingdesign-components";

//import { pdEyeImage, pdResult } from "./mock";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { drawPdCanvas, changePdResult, clearPdInterval } from "./ar";
import { useNavigate } from "react-router-dom";
import { stopApp } from "../../../public/API/pd_scan";
import screenfull from "screenfull";
// import { DataContext } from "../../App";
import { useRescan } from '../../hooks/useRescan'
import { startEvent } from "../../../public/API/pd_scan";
//import { currentLanguage } from "../../i18n";
import { LanguageContext } from '../../LanguageContext';

const ResultAdjustmentCard = () => {
  const navigate = useNavigate();
  const navigateHanldler = useRescan("pd")

  const goNext = () => {
    startEvent([['event_type', 'view_summary'], ['summary_type', 'pd'], ['pdResult', JSON.stringify(pdResult)]]);
    navigate("/pd-result-page/pd");
  };
  const canvasRef = useRef();

  const pdEyeImage = useMemo(
    () => JSON.parse(localStorage.getItem("pdEyeImage")),
    []
  );
  const pdResult = useMemo(
    () => JSON.parse(localStorage.getItem("pdResult")),
    []
  );

  useEffect(() => {

    console.log("Data after scan:" + pdEyeImage);
  }, []);

  const [results, setResults] = useState({
    leftPD: pdResult?.leftEyePd.mm || 0,
    rightPD: pdResult?.rightEyePd.mm || 0,
    totalPD: (
      parseFloat(pdResult.leftEyePd.mm) + parseFloat(pdResult.rightEyePd.mm)
    ).toFixed(1),
  });

  useEffect(() => {
    drawPdCanvas(pdEyeImage, pdResult, canvasRef.current);
  }, []);
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;

  const handleButtonClick = useCallback((category, direction) => {
    changePdResult(
      category,
      direction,
      pdResult,
      canvasRef.current,
      setResults,
      //pdResult.PDmm,
      pdEyeImage
    );
  }, []);

  const reScanHandler = () => {
    navigateHanldler("pd")
  }

  return (
    <>
      <div>
        <div className={styles.canvas}>
          <canvas ref={canvasRef}></canvas>
          <CanvasOverlay
            preserveAspectRatio="none"
            className={styles.canvasOverlay}
          />
        </div>
        <div className={styles.controls}>
          <span className={styles.controlsHeader}>
            {/* Edit results using the buttons below */}
            {t.pd_result_title[currentLanguage]}
          </span>
          <div className={styles.controlBoxes}>
            <Card className={styles.eyeControlCard}>
              <div className={styles.controlBoxHeader}>
                {/* Left PD */}
                {t.left_pd_title[currentLanguage]}
              </div>
              <Divider className={styles.controlBoxSeparator} />
              <div className={styles.controlBoxButtons}>
                <div className={styles.button}>

                  <ArrowUp
                    onTouchStart={() => {
                      handleButtonClick("leftEye", "up");
                    }}
                    onTouchEnd={(e) => {
                      clearPdInterval(pdResult);
                    }}
                  />

                </div>
                <div className={styles.sideButtons}>
                  <div className={styles.button}>

                    <ArrowLeft
                      onTouchStart={() => {
                        handleButtonClick("leftEye", "left");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                  <div className={styles.button}>
                    <ArrowRight
                      onTouchStart={() => {
                        handleButtonClick("leftEye", "right");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                </div>
                <div className={styles.button}>
                  <ArrowDown
                    onTouchStart={() => {
                      handleButtonClick("leftEye", "down");
                    }}
                    onTouchEnd={() => clearPdInterval(pdResult)}
                  />
                </div>
              </div>
              <div className={styles.controlBoxFooter}>{results.leftPD}
                {t.unit[currentLanguage]}
              </div>
              {/* <div className={styles.controlBoxFooter}>
                {pdResult.leftEyePd.mm}mm
              </div> */}
            </Card>

            <Card className={styles.eyeControlCard}>
              <div className={styles.controlBoxHeader}>
                {t.nose_title[currentLanguage]}
              </div>
              <Divider className={styles.controlBoxSeparator} />
              <div className={styles.controlBoxButtons}>
                <div className={styles.noseButtons}>
                  <div className={styles.button}>
                    <ArrowLeft
                      onTouchStart={() => {
                        handleButtonClick("nose", "left");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                  <div className={styles.button}>
                    <ArrowRight
                      onTouchStart={() => {
                        handleButtonClick("nose", "right");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                </div>
              </div>
            </Card>
            <Card className={styles.eyeControlCard}>
              <div className={styles.controlBoxHeader}>
                {/* Right PD */}
                {t.right_pd_title[currentLanguage]}
              </div>
              <Divider className={styles.controlBoxSeparator} />
              <div className={styles.controlBoxButtons}>
                <div className={styles.button}>
                  <ArrowUp
                    onTouchStart={() => {
                      handleButtonClick("rightEye", "up");
                    }}
                    onTouchEnd={() => clearPdInterval(pdResult)}
                  />
                </div>
                <div className={styles.sideButtons}>
                  <div className={styles.button}>
                    <ArrowLeft
                      onTouchStart={() => {
                        handleButtonClick("rightEye", "left");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                  <div className={styles.button}>
                    <ArrowRight
                      onTouchStart={() => {
                        handleButtonClick("rightEye", "right");
                      }}
                      onTouchEnd={() => clearPdInterval(pdResult)}
                    />
                  </div>
                </div>
                <div className={styles.button}>
                  <ArrowDown
                    onTouchStart={() => {
                      handleButtonClick("rightEye", "down");
                    }}
                    onTouchEnd={() => clearPdInterval(pdResult)}
                  />
                </div>
              </div>
              <div className={styles.controlBoxFooter}>{results.rightPD}
                {t.unit[currentLanguage]}
              </div>
            </Card>
          </div>
          <div className={styles.controlsFooter}>
            {/* Binocular PD:{" "} */}
            {t.summary_total_pd[currentLanguage]}:{" "}
            {/* <span className={styles.controlsHeader}>{results.totalPD}mm</span> */}
            <span className={styles.controlsHeader}>
              {(
                parseFloat(results.leftPD) + parseFloat(results.rightPD)
              ).toFixed(1)}
              {/* mm */}
              {t.unit[currentLanguage]}
            </span>
          </div>
        </div>

        <div className={styles.buttonContainer}>

          <Button size="medium" className={styles.btnWrapper} onClick={goNext}>
            {/* Next */}
            {t.next[currentLanguage]}
            <KeyboardArrowRight />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultAdjustmentCard;

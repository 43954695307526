import "./App.css";
import React, { useEffect, useMemo, useState, Context, createContext } from "react";
import "@livingdesign/bogle/dist/Bogle.css";
import "@walmart-web/livingdesign-components/index.esm.css";
import { startApp } from "../public/API/pd_scan";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import PreScan from "./routes/PreScan";
import PreScanSh from "./routes/PreScanSh";
import Root from "./routes/Root";
import CameraInstruction from "./routes/CameraInstruction";
import ResultSummary from "./routes/ResultSummary";
import PdManual from "./routes/PdManual";
import ResultAdjustment from "./routes/ResultAdjustment";
import ShResultAdjust from "./routes/ShResultAdjust";
import Loading from "./routes/Loading";
import ProgressBar from "./routes/ProgressBar";
import Landing from "./routes/Landing";
import data from "./assets/data.json";
import ProgressComp from "./components/scanloading/ProgressComp";
import { LanguageContext } from '../src/LanguageContext';
//import { useVisitCounter } from './hooks/useVisitCounter'

// export const DataContext = createContext(data);
localStorage.setItem("settingsData", JSON.stringify(data));


function App() {
  const savedLanguage = localStorage.getItem('appLanguage');
  //const savedStore = localStorage.getItem('selectedStoreID');
  //savedStore == null ? localStorage.setItem('selectedStoreID', " ") : savedStore;
  //if (!savedStore) localStorage.setItem('selectedStoreID', 0);

  const [appLanguage, setAppLanguage] = useState(savedLanguage === null ? 'english' : savedLanguage);
  const handleLanguageChange = (language) => {
    localStorage.setItem('appLanguage', language)
    setAppLanguage(language)
  }
  const languageContextValue = {
    appLanguage, setAppLanguage: handleLanguageChange
  }

  useEffect(async () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const camera = urlParams.get('camera')
    if (camera === "front") {
      startApp(0);
    } else {
      startApp(1);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("pdResult");
    localStorage.removeItem("pdEyeImage");
    localStorage.removeItem("shResult");
    localStorage.removeItem("shEyeImage");
    // console.log("empty local storage");
  }, []);

  // const { clientID, visitCount } = useVisitCounter()
  // console.log(clientID, visitCount);



  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
    },
    {
      path: "prescan",
      element: <PreScan />,
      errorElement: <ErrorPage />,
    },
    {
      path: "prescansh",
      element: <PreScanSh />,
      errorElement: <ErrorPage />,
    },
    {
      path: "configuration/:scantype",
      element: <CameraInstruction />,
      errorElement: <ErrorPage />,
    },
    {
      path: "pd-result-page/:scantype",
      element: <ResultSummary />,
      errorElement: <ErrorPage />,
    },
    {
      path: "sh-result-page/:scantype",
      element: <ResultSummary />,
      errorElement: <ErrorPage />,
    },
    {
      path: "pdmanual-page",
      element: <PdManual />,
      errorElement: <ErrorPage />,
    },
    {
      path: "pd-result-adjustment",
      element: <ResultAdjustment />,
      errorElement: <ErrorPage />,
    },
    {
      path: "sh-result-adjustment",
      element: <ShResultAdjust />,
      errorElement: <ErrorPage />,
    },
    {
      path: "loading/:scantype",
      // element: <Loading />,
      element: <ProgressComp />,
      errorElement: <ErrorPage />,
    },
    {
      path: "landing",
      element: <Landing />,
      errorElement: <ErrorPage />,
    },

  ]);

  //  }
  return (

    // <DataContext.Provider value={data}>
    <LanguageContext.Provider value={languageContextValue}>
      <div className="App">
        {/* <LanguageContext.Provider value={languageContextValue}> */}
        <RouterProvider router={router} />
        {/* </LanguageContext.Provider> */}
      </div>
    </LanguageContext.Provider>
    // </DataContext.Provider>
  );
}

export default App;

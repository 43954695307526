import React, { useState, useMemo, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import styles from "./LanguageSelector.module.css";
import dropdownSVG from "../assets/dropdown.svg";
import { Icon, InfoCircle, Globe, Check } from "@livingdesign/icons";
import { IconButton, Menu, MenuItem } from "@walmart-web/livingdesign-components";
import { LanguageContext } from '../LanguageContext';
import Content from './Content';
import Root from '../routes/Root';


const LanguageSelector = () => {

    const { appLanguage, setAppLanguage } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = React.useState(false);
    const triggerRef = React.useRef();

    return (
        < div >
            <Menu className={styles.menu}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                trigger={
                    <div>
                        <Globe size="large" />
                        <img src={dropdownSVG} ref={triggerRef} />
                    </div>
                }
                triggerRef={triggerRef}
            >
                <MenuItem onTouchStart={() => setAppLanguage('english')}><div className={styles.menuItem}><div className={styles.checkContainer}>{appLanguage === 'english' && <Check />}</div>English</div></MenuItem>
                <MenuItem onTouchStart={() => setAppLanguage('spanish')}><div className={styles.menuItem}><div className={styles.checkContainer}>{appLanguage === 'spanish' && <Check />}</div>Spanish</div></MenuItem>
            </Menu>
            {/* </span> */}
        </div >
    )
}

export default LanguageSelector
// TODO: use real data, instead of mock data
//import { pdEyeImage, pdResult } from "./mock";
//import { shEyeImage, shResult } from "./mock";

//import * as $ from 'jquery';
//import { setEvent } from "../../../public/API/AR";
import { startEvent } from "../../../public/API/pd_scan";

// const darkColor = "#003896";
// const mainColor = "#1A75CF";
// const lightColor = "#6CABE7";
// const darkColor = "#FFF200";
const darkColor = "#FFFFFF";
const mainColor = "#FFF200";
const lightColor = "#FFF200";
var pdChangeInterval;
var pdChangeCount = 0;
var isManualPd;
//var shResult;
var language;
var version = "2.0";
var clientId = "0";
var sessionId;
var mirrorId = "5f036e6895acad0c72db1438";
var pdChangeInterval;
var keepAliveValue = 0;
var shChangeInterval;
var shChangeCount = 0;



function createCanvasImage(iEyeArea, pdResultCanvas, shResult) {
  console.log("iEyeArea ", iEyeArea);
  pdResultCanvas.width = iEyeArea.pos[2];
  pdResultCanvas.height = iEyeArea.pos[3];
  var canvasWidth =
    ((window.innerHeight * 0.4 * pdResultCanvas.width) /
      pdResultCanvas.height /
      window.innerWidth) *
      100 <
    100
      ? ((window.innerHeight * 0.4 * pdResultCanvas.width) /
          pdResultCanvas.height /
          window.innerWidth) *
        100
      : 100;

  pdResultCanvas.style.width = canvasWidth + "vw";

  var ctx = pdResultCanvas.getContext("2d");
  /*
     var imgData = window.atob(iEyeArea.data);
     var rawLength = imgData.length;
     var array = new Uint8Array(new ArrayBuffer(rawLength));
     for(var i=0;i<rawLength;i++){
         array[i] = imgData.charCodeAt(i)
     }
     putImageData(ctx,iEyeArea.channels, array, iEyeArea.pos[3],  iEyeArea.pos[2]);
 */
  var data = iEyeArea.data2 ? iEyeArea.data2 : iEyeArea.data;
  putImageData(ctx, iEyeArea.channels, data, iEyeArea.pos[3], iEyeArea.pos[2]);

  function putImageData(ctx, channels, data, w, h) {
    var pos = 0;
    for (var x = 0; x < w; x++) {
      for (var y = 0; y < h; y++) {
        var alpha = 255;
        if (channels == 4) {
          alpha = data[pos + 3];
        }
        ctx.fillStyle =
          "rgba(" +
          data[pos] +
          "," +
          data[pos + 1] +
          "," +
          data[pos + 2] +
          "," +
          alpha +
          ")";
        ctx.fillRect(h - 1 - y, x, 1, 1);
        pos += channels;
      }
    }
  }
  //if (shResult.leftEyeSh.mm > 25) pdResultCanvas.style.height = "90%";
  // if (shResult.leftEyeSh.mm > 25) pdResultCanvas.style.height = "32vh";

  // pdResultCanvas.style.width = "90vw";
  // const aspectRatio = pdResultCanvas.height / pdResultCanvas.width;

  // // const canvasWidth = canvasHeight / aspectRatio;
  // canvasWidth = 85 / aspectRatio;
  // // canvas.width = canvasWidth;
  // // canvas.height = canvasHeight;
  // console.log("aspectRatio ", aspectRatio);
  // pdResultCanvas.style.width = canvasWidth + "vw";
  //pdResultCanvas.style.height = pdResultCanvas.height + "vh";

  if (!shResult) pdResultCanvas.style.width = "100%";
  if (shResult)
    console.log(
      "sh result sizes ",
      "shResult.leftEye ",
      shResult.leftEye,
      "shResult.leftEye[0] ",
      shResult.leftEye[0],
      "shResult.leftEye[1]",
      shResult.leftEye[1],
      "leftEyeSh.mm",
      shResult.leftEyeSh.mm
    );

  console.log(
    "pdResultCanvas.style.width ",
    pdResultCanvas.style.width,
    "pdResultCanvas.style.height ",
    pdResultCanvas.style.height,
    "pdResultCanvas.height",
    pdResultCanvas.height
    // "shResult.leftEye ",
    // shResult.leftEye,
    // "shResult.leftEye[0] ",
    // shResult.leftEye[0],
    // "shResult.leftEye[1]",
    // shResult.leftEye[1],
    // "leftEyeSh.mm",
    // shResult.leftEyeSh.mm
  );
}

function drawLine(a, b, color, ctx) {
  ctx.beginPath();
  ctx.moveTo(a[1], a[0]);
  ctx.lineTo(b[1], b[0]);
  ctx.strokeStyle = color;
  ctx.stroke();
}

export function drawPdCanvas(pdEyeImage, pdResult, canvasElement) {
  console.log("draw ", "test draw params ", canvasElement);
  const ctx = canvasElement.getContext("2d");

  createCanvasImage(pdEyeImage, canvasElement);
  drawLine(pdResult.leftEye, pdResult.rightEye, darkColor, ctx);
  drawLine(
    [pdResult.leftEye[0] - 10, pdResult.leftEye[1]],
    [pdResult.leftEye[0] + 10, pdResult.leftEye[1]],
    mainColor,
    ctx
  );
  drawLine(
    [pdResult.rightEye[0] - 10, pdResult.rightEye[1]],
    [pdResult.rightEye[0] + 10, pdResult.rightEye[1]],
    mainColor,
    ctx
  );
  drawLine(
    [pdResult.nose[0] - 10, pdResult.nose[1]],
    [pdResult.nose[0] + 10, pdResult.nose[1]],
    lightColor,
    ctx
  );
}

// -------------------GUI functions ---------------------------------

function calcDistance(a, b) {
  return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
}

function adjustNose(a, b, c) {
  var m = (a[0] - b[0]) / (a[1] - b[1]);
  var n = a[0] - m * a[1];
  c[0] = m * c[1] + n;
}

// export function changePdResult(iEyeSide, iDirection) {
export function changePdResult(
  iEyeSide,
  iDirection,
  pdResult,
  canvasElement,
  setResults,
  pdEyeImage
) {
  
  console.log(
    "changePd",
    iEyeSide,
    iDirection,
    pdResult,
    "canvas ",
    canvasElement
  );
  if (!pdChangeInterval) {
    pdChangeInterval = setInterval(() => {
      pdChangeCount++;
      let pixelChange = 0.25 + pdChangeCount / 10;

      if (iEyeSide == "leftEye") {
        changePixels(pdResult.leftEye, iDirection, pixelChange, pdResult);
      } else if (iEyeSide == "rightEye") {
        changePixels(pdResult.rightEye, iDirection, pixelChange, pdResult);
      } else {
        changePixels(pdResult.nose, iDirection, pixelChange, pdResult);
      }
      //calcPd(pdResult, languageText, setRightPD, setLeftPD);
      calcPd(pdResult, setResults);
      drawPdCanvas(pdEyeImage, pdResult, canvasElement); //error ?
    }, 50);
  }
}
export function clearPdInterval(pdResult) {
  // console.log("clear interval");
  startEvent([
    ["event_type", "change_pd_result"],
    ["pdResult", JSON.stringify(pdResult)],
  ]);

  // setEvent([
  //   ["event_type", "change_pd_result"],
  //   ["pdResult", JSON.stringify(pdResult)],
  // ]);
  clearInterval(pdChangeInterval);
  console.log("clear PD interval pdChangeInterval",pdChangeInterval,"PD result ", pdResult);

  pdChangeInterval = undefined;
  pdChangeCount = 0;
}
// export function setEvent(iAttributes) {
//   keepAliveValue = 0;
//   let versionId = version ? version : "no_version";
//   if (sessionId) {
//     var newDate = new Date().toISOString();
//     var url = "";
//     /*iAttributes = [['page_Id','1'],['activity','click'],['look_id','55'],['event-type','photo']];*/
//     var attr = "";
//     iAttributes.forEach(function (iAttr) {
//       if (attr != "") {
//         attr = attr.concat("&");
//       }
//       attr = attr.concat(iAttr[0], "=", iAttr[1]);
//     });
//     url = url.concat(attr);
//     var fullUrl =
//       "https://mmweb.westus.cloudapp.azure.com/AzureProxy/api/trace2?mirror=" +
//       mirrorId +
//       "&clientId=" +
//       clientId +
//       "&sessionId=" +
//       sessionId +
//       "&versionId=" +
//       versionId +
//       "&date=" +
//       newDate +
//       "&" +
//       url;
//     //console.log(fullUrl);
//     console.log("set event from UI ", fullUrl);
//     //fetch(fullUrl);
//     //$.get(fullUrl, function (result) {}, "text"); 
//   }
// }

export function changePixels(
  iEyeSide,
  iDirection,
  iPixelChange,
  pdResult,
  shResult
) {
  if (
    isManualPd ||
    (shResult && iDirection == "left") ||
    (shResult && iDirection == "right")
  ) {
    const pupilDistance = calcDistance(shResult.leftEye, shResult.rightEye);
    shResult.px2mm = Number(pdResult.PDmm) / pupilDistance;
  }
  const px = shResult
    ? iPixelChange / shResult.px2mm
    : iPixelChange / pdResult.px2mm;
  switch (iDirection) {
    case "up":
      iEyeSide[0] = iEyeSide[0] - px;
      break;
    case "down":
      iEyeSide[0] = iEyeSide[0] + px;
      break;
    case "left":
      iEyeSide[1] = iEyeSide[1] - px;
      break;
    case "right":
      iEyeSide[1] = iEyeSide[1] + px;
      break;
  }
}
// export function changePdPixels(iEyeSide, iDirection, iPixelChange, pdResult) {
//   if (
//     isManualPd ||
//     (shResult && iDirection == "left") ||
//     (shResult && iDirection == "right")
//   ) {
//     var pupilDistance = calcDistance(shResult.leftEye, shResult.rightEye);
//     shResult.px2mm = Number(pdResult.PDmm) / pupilDistance;
//   }
//   // var px = shResult
//   //   ? iPixelChange / shResult.px2mm
//   //   : iPixelChange / pdResult.px2mm;
//   var px = iPixelChange / pdResult.px2mm;

//   console.log(
//     "changePixels ",
//     iEyeSide,
//     iDirection,
//     iPixelChange,
//     pdResult,
//     "px ",
//     px,
//     " pupilDistance ",
//     pupilDistance,
//     "shResult ",
//     shResult
//   );
//   switch (iDirection) {
//     case "up":
//       iEyeSide[0] = iEyeSide[0] - px;
//       break;
//     case "down":
//       iEyeSide[0] = iEyeSide[0] + px;
//       break;
//     case "left":
//       iEyeSide[1] = iEyeSide[1] - px;
//       break;
//     case "right":
//       iEyeSide[1] = iEyeSide[1] + px;
//       break;
//   }
// }

export function calcPd(pdResult, setResults) {
  pdResult.PDpix = calcDistance(pdResult.leftEye, pdResult.rightEye);
  pdResult.PDmm = (pdResult.PDpix * pdResult.px2mm).toFixed(1);
  adjustNose(pdResult.leftEye, pdResult.rightEye, pdResult.nose);
  pdResult.leftEyePd.px = calcDistance(pdResult.leftEye, pdResult.nose);
  pdResult.leftEyePd.mm = (pdResult.leftEyePd.px * pdResult.px2mm).toFixed(1);
  pdResult.rightEyePd.px = calcDistance(pdResult.rightEye, pdResult.nose);
  pdResult.rightEyePd.mm = (pdResult.rightEyePd.px * pdResult.px2mm).toFixed(1);

  localStorage.setItem("pdResult", JSON.stringify(pdResult));

  // problem that point go back to center before moving ...

  setResults({
    rightPD: pdResult.rightEyePd.mm,
    leftPD: pdResult.leftEyePd.mm,
    totalPD: pdResult.PDmm,
  });
}

// ----------- SH functions -------------------

export function clearShInterval(shResult, pdResult) {
  // console.log("PD result ", pdResult, "SH result ", shResult);

  // setEvent([
  //   ["event_type", "change_sh_result"],
  //   ["pdResult", JSON.stringify(pdResult)],
  //   ["shResult", JSON.stringify(shResult)],
  // ]);
  startEvent([
    ["event_type", "change_sh_result"],
    ["pdResult", JSON.stringify(pdResult)],
    ["shResult", JSON.stringify(shResult)],
  ]);

  clearInterval(shChangeInterval);
  console.log("clear Sh interval shChangeInterval",shChangeInterval,"PD result ", pdResult, "SH result ", shResult);
  shChangeInterval = undefined;
  shChangeCount = 0;
}

export function calcSh(shResult, pdResult, setShResults) {
  adjustNose(shResult.leftEye, shResult.rightEye, shResult.nose);
  shResult.leftDbl[0] = shResult.nose[0];
  shResult.rightDbl[0] = shResult.nose[0];
  adjustNoseByDbl(shResult.leftDbl, shResult.rightDbl, shResult.nose);
  shResult.leftEyeSh.px = calcDistance(shResult.leftEye, shResult.leftGlasses);
  shResult.leftEyeSh.mm = (shResult.leftEyeSh.px * shResult.px2mm).toFixed(1);
  shResult.leftEyeOc.px = calcDistance(
    shResult.leftEyeBottom,
    shResult.leftGlasses
  );
  shResult.leftEyeOc.mm = (shResult.leftEyeOc.px * shResult.px2mm).toFixed(1);
  shResult.rightEyeSh.px = calcDistance(
    shResult.rightEye,
    shResult.rightGlasses
  );
  shResult.rightEyeSh.mm = (shResult.rightEyeSh.px * shResult.px2mm).toFixed(1);
  shResult.rightEyeOc.px = calcDistance(
    shResult.rightEyeBottom,
    shResult.rightGlasses
  );
  shResult.rightEyeOc.mm = (shResult.rightEyeOc.px * shResult.px2mm).toFixed(1);
  shResult.leftEyeBottom[1] = shResult.leftEye[1];
  shResult.leftGlasses[1] = shResult.leftEye[1];
  shResult.rightEyeBottom[1] = shResult.rightEye[1];
  shResult.rightGlasses[1] = shResult.rightEye[1];
  pdResult.PDpix = calcDistance(shResult.leftEye, shResult.rightEye);
  pdResult.leftEyePd.px = calcDistance(shResult.leftEye, shResult.nose);
  pdResult.rightEyePd.px = calcDistance(shResult.rightEye, shResult.nose);
  // TODO: set results
  setShResults({
    // rightSH: shResult.rightEyeSh.mm,
    // leftSH: shResult.leftEyeSh.mm,
    // rightFH: shResult.rightEyeOc.mm,
    // leftFH: shResult.leftEyeOc.mm,
    rightSH: shResult.rightEyeOc.mm,
    leftSH: shResult.leftEyeOc.mm,
    rightFH: shResult.rightEyeSh.mm,
    leftFH: shResult.leftEyeSh.mm,
    //totalPD: pdResult.PDmm,
  });

  localStorage.setItem("shResult", JSON.stringify(shResult));
  //======================
  // document.getElementsByClassName("left-result")[0].innerHTML =
  //   languageText["sh_left"][language] +
  //   ": " +
  //   shResult.leftEyeSh.mm +
  //   "mm <br>" +
  //   languageText["oc_left"][language] +
  //   ": " +
  //   shResult.leftEyeOc.mm +
  //   "mm";
  // document.getElementsByClassName("right-result")[0].innerHTML =
  //   languageText["sh_right"][language] +
  //   ": " +
  //   shResult.rightEyeSh.mm +
  //   "mm <br>" +
  //   languageText["oc_right"][language] +
  //   ": " +
  //   shResult.rightEyeOc.mm +
  //   "mm";
  // document.getElementsByClassName("total-pd")[0].innerHTML = "";
}

function adjustNoseByDbl(a, b, c) {
  c[1] = (a[1] + b[1]) / 2;
}

export function changeShResult(
  iEyeSide,
  iDirection,
  shResult,
  canvasElement,
  setShResults,
  shEyeImage,
  pdResult
) {
  if (!shChangeInterval) {
    shChangeInterval = setInterval(() => {
      shChangeCount++;
      let pixelChange = 0.25 + shChangeCount / 10;
      console.log(
        "changeShResult ",
        " iEyeSide ",
        iEyeSide,
        "iDirection ",
        iDirection
      );
      switch (iEyeSide) {
        case "rightDbl":
          changePixels(
            shResult.rightDbl,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "rightPupil":
          changePixels(
            shResult.rightEye,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "rightBottom":
          changePixels(
            shResult.rightEyeBottom,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "rightFrame":
          changePixels(
            shResult.rightGlasses,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "leftDbl":
          changePixels(
            shResult.leftDbl,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "leftPupil":
          changePixels(
            shResult.leftEye,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "leftBottom":
          changePixels(
            shResult.leftEyeBottom,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
        case "leftFrame":
          changePixels(
            shResult.leftGlasses,
            iDirection,
            pixelChange,
            pdResult,
            shResult
          );
          break;
      }

      calcSh(shResult, pdResult, setShResults);
      drawShCanvas(shEyeImage, shResult, canvasElement);
    }, 50);
  }
}

export function drawShCanvas(shEyeImage, shResult, canvasElement) {
  createCanvasImage(shEyeImage, canvasElement, shResult);
  const ctx = canvasElement.getContext("2d");

  drawLine(shResult.leftEye, shResult.leftGlasses, darkColor, ctx);
  drawLine(shResult.rightEye, shResult.rightGlasses, darkColor, ctx);
  drawLine(
    [shResult.leftEye[0], shResult.leftEye[1] - 10],
    [shResult.leftEye[0], shResult.leftEye[1] + 10],
    mainColor,
    ctx
  );
  drawLine(
    [shResult.leftEyeBottom[0], shResult.leftEyeBottom[1] - 10],
    [shResult.leftEyeBottom[0], shResult.leftEyeBottom[1] + 10],
    lightColor,
    ctx
  );
  drawLine(
    [shResult.leftGlasses[0], shResult.leftGlasses[1] - 10],
    [shResult.leftGlasses[0], shResult.leftGlasses[1] + 10],
    mainColor,
    ctx
  );
  drawLine(
    [shResult.rightEye[0], shResult.rightEye[1] - 10],
    [shResult.rightEye[0], shResult.rightEye[1] + 10],
    mainColor,
    ctx
  );
  drawLine(
    [shResult.rightEyeBottom[0], shResult.rightEyeBottom[1] - 10],
    [shResult.rightEyeBottom[0], shResult.rightEyeBottom[1] + 10],
    lightColor,
    ctx
  );
  drawLine(
    [shResult.rightGlasses[0], shResult.rightGlasses[1] - 10],
    [shResult.rightGlasses[0], shResult.rightGlasses[1] + 10],
    mainColor,
    ctx
  );
}

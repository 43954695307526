import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import {
  ButtonGroup,
  Card,
  TextField,
} from "@walmart-web/livingdesign-components";
import styles from "./ErrorHandler.module.css";
import { useNavigate } from "react-router-dom";

import { Button } from "@walmart-web/livingdesign-components";
import errorimage from "../assets/redcircle.svg";
import icon from "../assets/Icon.svg";
import { stopApp } from "../../public/API/pd_scan";
import screenfull from "screenfull";
import { useRescan } from "../hooks/useRescan";
import { LanguageContext } from '../LanguageContext';
//import { currentLanguage } from "../i18n";


const ErrorHandler = (props) => {
  const currentLanguage = useContext(LanguageContext).appLanguage;
  const settingsData = useMemo(
    () => JSON.parse(localStorage.getItem("settingsData")),
    []
  );
  const t = settingsData.languageText;

  let { scantype, errormessage } = props;
  const navigateHanldler = useRescan(scantype)
  if (!scantype) scantype = "PD";
  //if (!errormessage) errormessage = "Scan failed";
  const scanerror = t.rescan[currentLanguage];
  const scanagain = t.scan_again[currentLanguage];

  if (!errormessage) errormessage = { scanerror }
  //if (!errormessage) errormessage = { error };

  const navigate = useNavigate();
  const navigationHanlder = () => {
    screenfull.exit()
    stopApp()
    // navigate("/")
  };

  const rescanHandler = () => {
    navigateHanldler(scantype)
  }

  return (
    <div className={styles.main}>
      <div className={styles.overlaywrapper}>

        <div className={styles.controls}>
          <div className={styles.xBtn}>
            <img alt="icon" src={icon} style={{ position: "absolute" }} />
            <img alt="errorimage" src={errorimage} style={{ height: "4vh" }} />
          </div>
          <div className={styles.controlsHeader}>{errormessage}</div>
          <div className={styles.errorMessage}>
            {/* {!(props.errorMessage === "networkError") &&
              `Try again to scan your ${scantype}`
            } */}
            {!(props.errorMessage === "networkError") &&
              `${scanagain} ${scantype}`
            }

          </div>
          <div className={styles.actionBtnWrapper}>

            {/* <Button
              size="medium"
              className={styles.restartApp}
              onClick={navigationHanlder}
            >
              {t.restart[currentLanguage]}
            
            </Button> */}
            <Button
              size="medium"
              className={styles.btnWrapper}
              onClick={rescanHandler}
            >
              {/* Rescan */}
              {t.rescan[currentLanguage]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandler;
